import allGamesIcon from 'assets/img/PageTitles/Games_Icon.svg';

import { useLocalization } from 'components/Internationalization';

import PageTitle from 'components/Base/PageTitle';

const Title: React.FC = () => {
  const { translate } = useLocalization();

  return (
    <>
      <PageTitle
        icon={allGamesIcon}
        title={translate('games.title')}
        lineColor="transparent"
        titleSize="small"
        largeImage
      />
    </>
  );
};

export default Title;
