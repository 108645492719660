import TableRow from 'components/Base/NewTable/components/TableRow';

import styles from './styles.module.scss';
import { ITableBody } from './types';

const TableBody: React.FC<ITableBody> = ({ rows, empty }) => {
  return (
    <tbody className={styles.body}>
      {rows.map((r, index) => (
        <TableRow {...r} key={r.id} cells={r.cells} index={index} />
      ))}
      {!rows.length ? (
        <tr className={styles.emptyRow}>
          <td className={styles.emptyCell}>{empty ?? <p>There are no entries yet</p>}</td>
        </tr>
      ) : null}
    </tbody>
  );
};

export default TableBody;
