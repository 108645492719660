import { pathOr } from 'ramda';
import { useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';

import wheelIcon from 'assets/img/Activities/wheel.webp';
import bonusIcon from 'assets/img/Activities/bonus.webp';

import { useAppSelector } from 'hooks/useAppSelector';
import { ContextPopUps } from 'context';
import { Links } from 'components/constants';
import { EWheelImg, WheelStatus } from 'components/constants/games';
import { popUps, SettingName } from 'components/constants/constants';
import { GET_COMMON_SETTINGS } from 'graphQl/query/settings/bonusSettings';
import { GET_WHEEL_STILE } from 'graphQl/query/wheel/wheel';
import { getCommonSettingsValue } from 'func/common';
import { getMyBonusCount } from 'func/prepareDataBonus';
import { media } from 'config';
import { dataUserBonuses } from 'store/data/data.selectors';
import { userToken } from 'store/user/user.selectors';
import { useNavigate } from 'hooks/useNavigate';

import Activity from './Activity';
// import RakebackActivity from './RakebackActivity';

import styles from './styles.module.scss';
import { IBurgerBonus } from './types';

const BurgerActivities: React.FC<IBurgerBonus> = ({ menuOpen, onOpenMenu }) => {
  const navigate = useNavigate();
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const userBonuses = useAppSelector(dataUserBonuses);
  const token = useAppSelector(userToken);

  const [wheelDefault, setWheelDefault] = useState(true);
  const [wheelColor, setWheelColor] = useState('#FDD2E2');
  const [wheelIconSrc, setWheelIconSrc] = useState(wheelIcon);
  const [wheelActive, setWheelActive] = useState(false);
  const [bonusCount, setBonusCount] = useState(0);

  const { data: mainSettings } = useQuery(GET_COMMON_SETTINGS, { fetchPolicy: 'cache-only' });

  const { data: dataWheelStyle } = useQuery(GET_WHEEL_STILE, {
    fetchPolicy: 'cache-only',
  });

  useEffect(() => {
    if (dataWheelStyle) {
      const hexRegex = /#(?:[0-9a-fA-F]{3,6})/gim;

      const wheelStyle = pathOr<string>('', ['wheelStyle', 'wheel_style'], dataWheelStyle);
      const wheelButtonColor = pathOr('', ['wheelStyle', 'wheel_button_color'], dataWheelStyle);
      const newWheelColors = wheelButtonColor.match(hexRegex);

      if (wheelStyle) {
        setWheelDefault(false);
        setWheelIconSrc(`${media.wheel}${wheelStyle}/${EWheelImg.wheelHeader}`);
      }

      if (newWheelColors) {
        const [newWheelColor] = newWheelColors;

        setWheelColor(newWheelColor);
      }
    }
  }, [dataWheelStyle]);

  useEffect(() => {
    if (mainSettings) {
      const wheelSt = getCommonSettingsValue(mainSettings.getSettings, SettingName.wheelStatus);

      setWheelActive(wheelSt === WheelStatus.active);
    }
  }, [mainSettings]);

  useEffect(() => {
    if (token && userBonuses) {
      const bonusesList = getMyBonusCount(userBonuses);
      setBonusCount(bonusesList);
    }
  }, [userBonuses, token]);

  const handleMenu = () => {
    onOpenMenu(!menuOpen);
    scroll.scrollTo(0, { duration: 0, delay: 0 });
  };

  const handleWheel = () => {
    setPopUpsOpen({
      modalOpen: popUps.wheelFortune,
      data: { config: popUps.wheelFortune },
    });

    handleMenu();
  };

  const handleBonus = () => {
    navigate(Links.bonus);
    handleMenu();
  };

  return (
    <div className={styles.wrapper}>
      {wheelActive ? (
        <Activity
          title="Fortune Wheel"
          color={wheelColor}
          icon={wheelIconSrc}
          customStyle={!wheelDefault}
          onClick={handleWheel}
        />
      ) : null}
      {/* <RakebackActivity onToggleMenu={handleMenu} /> */}
      <Activity title="My Bonus" color="#F1D2AE" count={bonusCount} icon={bonusIcon} onClick={handleBonus} />
    </div>
  );
};

export default BurgerActivities;
