/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ITableCell } from 'components/Base/NewTable/components/TableCell/types';
import { ITableRow } from 'components/Base/NewTable/components/TableRow/types';

import { ETokenCode } from 'components/constants/constants';
import { getAmountWithoutZeroEnd } from 'func/common';
import { IEventPlayerResult } from 'components/Base/Event/types';

import TablePlace from 'components/Base/NewTable/components/TablePlace';
import TablePlayer from 'components/Base/NewTable/components/TablePlayer';
import TableWager from 'components/Base/NewTable/components/TableWager';

const prepareEventResultCells = (
  event: IEventPlayerResult,
  i: number,
  wagerCode?: string,
  prizeCode?: string,
  usdPrize?: boolean,
): ITableCell[] => {
  const placeCell: ITableCell = {
    id: 'event-result-place-' + event.id,
    content: <TablePlace place={i + 1} />,
  };

  const playerCell: ITableCell = {
    id: 'event-result-player-' + event.id,
    content: <TablePlayer avatar={event.avatar} rank={event.rank} alias={event.alias} />,
  };

  const wagerCell: ITableCell = {
    id: 'event-result-wager-' + event.id,
    content: <TableWager amount={event.totalWager || '0'} tokenCode={wagerCode} />,
  };

  const prizeCell: ITableCell = {
    id: 'event-result-prize-' + event.id,
    content: (
      <TableWager amount={event.prize} tokenCode={prizeCode} format={usdPrize ? getAmountWithoutZeroEnd : null} />
    ),
  };

  return [placeCell, playerCell, wagerCell, prizeCell];
};

export const prepareEventResultsRows = (
  events: IEventPlayerResult[],
  wagerCode?: string,
  prizeCode?: string,
): ITableRow[] => {
  const USD_CURRENCIES: string[] = [ETokenCode.USD, ETokenCode.USDT];

  const usdPrize = USD_CURRENCIES.includes(prizeCode!);

  return events.map<ITableRow>((e, i) => ({
    id: e.id,
    cells: prepareEventResultCells(e, i, wagerCode, prizeCode, usdPrize),
  }));
};
