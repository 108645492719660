import { ApolloLink, DefaultContext, FetchResult, HttpLink, Observable } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import config from 'config';
import { GraphqlErrors } from 'components/constants';
import { getAuthToken, validateJWT } from 'func/auth';
import type { RefreshTokens } from 'context/contextSessionManagement/types';

const { graphqlUrl } = config;

export const createHTTPLink = () => {
  return new HttpLink({ uri: graphqlUrl });
};

export const createAuthLink = () => {
  return new ApolloLink((operation, forward) => {
    const authToken = getAuthToken();

    operation.setContext(({ headers = {} }: DefaultContext) => ({
      headers: {
        ...headers,
        authorization: authToken ? `Bearer ${authToken}` : null,
      },
    }));

    return forward(operation);
  });
};

export const createErrorLink = (refreshTokens: RefreshTokens, logout: VoidFunction) => {
  return onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      const unauthorizedError = graphQLErrors.some((e) => {
        const formattedMessage = e.message?.toLowerCase();

        return formattedMessage === GraphqlErrors.unauthorized || formattedMessage === GraphqlErrors.unauthenticated;
      });

      if (unauthorizedError) {
        const observable = new Observable<FetchResult<Record<string, never>>>((observer) => {
          (async () => {
            try {
              await refreshTokens().then((res) => {
                if (!res) {
                  return forward(operation);
                }

                const { authToken } = res;

                const isTokenValid = validateJWT(authToken);

                if (authToken && isTokenValid) {
                  operation.setContext(({ headers = {} }) => ({
                    headers: {
                      ...headers,
                      authorization: `Bearer ${authToken}`,
                    },
                  }));

                  const subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                  };

                  forward(operation).subscribe(subscriber);
                } else {
                  logout();
                }
              });
            } catch (err) {
              observer.error(err);
            }
          })();
        });

        return observable;
      }
    }
  });
};
