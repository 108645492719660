import { gql } from '@apollo/client';

export const EVENTS = gql`
  query events {
    events {
      id
      slug
      name
      status
      type
      prize
      prizeTokenCode
      prizeTokenCodeDisplayName
      totalWagerTokenCode
      image
      startAt
      endAt
    }
  }
`;

export const EVENT = gql`
  query event($slug: String!) {
    event(slug: $slug) {
      id
      name
      prize
      prizeTokenCode
      prizeTokenCodeDisplayName
      rules
      slug
      startAt
      status
      tokens
      totalWagerTokenCode
      type
      eventRewardsCode
      endAt
      description
      image
      deskBanner
      tabBanner
    }
  }
`;

export const CLOSED_EVENTS = gql`
  query closedEvents($input: EventsPaginationParams!) {
    closedEvents(paginationData: $input) {
      items {
        id
        name
        prizeTokenCode
        prize
        startAt
        prizeTokenCodeDisplayName
        totalWagerTokenCode
        type
      }
    }
  }
`;

export const CURRENT_EVENT = gql`
  query eventCurrentResult($id: String!) {
    eventCurrentResult(eventId: $id) {
      id
      alias
      avatar
      rank
      totalWager
      prize
    }
  }
`;

export const CURRENT_EVENT_SELF = gql`
  query currentUserEventResult($id: String!) {
    currentUserEventResult(eventId: $id) {
      place
      totalWager
      prize
    }
  }
`;
