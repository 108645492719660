import dayjs from 'dayjs';
import { useTimer } from 'react-timer-hook';
import { useEffect, useState } from 'react';

import clockIcon from 'assets/img/common/clock.svg';

import { useAppSelector } from 'hooks/useAppSelector';
import { getTournamentTimerState } from 'func/prepareDataTournaments';
import { userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';

import styles from '../styles.module.scss';
import { IUserBonusCardFrontTimer } from '../types';

const UserBonusCardFrontTimer: React.FC<IUserBonusCardFrontTimer> = ({ startAt, endAt, depositDuration }) => {
  const { translate } = useLocalization();

  const [timerState, setTimerState] = useState('');
  const [isEventStarted, setIsEventStarted] = useState(false);
  const [isEventFinished, setIsEventFinished] = useState(false);
  const [duration, setDuration] = useState('2');

  const { days, hours, minutes, seconds, restart } = useTimer({
    expiryTimestamp: dayjs(endAt).toDate(),
    onExpire: () => handleExpire(isEventStarted, true),
  });

  const token = useAppSelector(userToken);

  useEffect(() => {
    const now = dayjs();
    const started = dayjs(startAt).isBefore(now);
    const finished = dayjs(endAt).isAfter(now);
    const countDownDate = dayjs(started ? endAt : startAt).toDate();

    restart(countDownDate, true);

    setIsEventStarted(started);
    setIsEventFinished(finished);
  }, [startAt, endAt]);

  useEffect(() => {
    setTimerState(getTournamentTimerState(isEventFinished, isEventStarted));
  }, [isEventStarted, isEventFinished, seconds]);

  useEffect(() => {
    if (!token && depositDuration) {
      // depositDuration - in minutes
      const durationDays = depositDuration / 60 / 24;

      setDuration(Math.ceil(durationDays).toString());
    }
  }, [token, depositDuration]);

  function handleExpire(started: boolean, finished: boolean) {
    setTimerState(getTournamentTimerState(started, finished));
  }

  return (
    <div className={styles.userBonusFrontContentInfoTimer}>
      <h4>{translate(timerState)}:</h4>
      <div className={styles.userBonusFrontContentInfoTimerContent}>
        <img src={clockIcon} alt="timer" />
        <div className={styles.userBonusFrontContentInfoTimerItem}>
          {token ? <p>{Number(days) > 0 ? days : '00'}</p> : <p>{duration}</p>}
          <p>d</p>
        </div>
        <div className={styles.userBonusFrontContentInfoTimerItem}>
          <p>{Number(hours) > 0 ? hours : '00'}</p>
          <p>h</p>
        </div>
        <div className={styles.userBonusFrontContentInfoTimerItem}>
          <p>{Number(minutes) > 0 ? minutes : '00'}</p>
          <p>m</p>
        </div>
        <div className={styles.userBonusFrontContentInfoTimerItem}>
          <p>{Number(seconds) > 0 ? seconds : '00'}</p>
          <p>s</p>
        </div>
      </div>
    </div>
  );
};

export default UserBonusCardFrontTimer;
