import React, { useCallback, useContext, useState } from 'react';
import cancel from 'assets/img/common/closePopUp.webp';
import { ContextPopUps } from 'context';
import { popUps } from 'components/constants/constants';
import MinesBetDetails from 'components/Base/PopUps/components/MinesBetDetails';
import WalletNavigation from './components/base/WalletNavigation';
import LogIn from './components/LogIn';
import PopUpsStyle from './styles';
import ForgotPassword from './components/ForgotPassword';
import PasswordSuccess from './components/PasswordSuccess';
import SignUp from './components/SignUp';
import SignUpConfirm from './components/SignUpConfirm';
import Collect from './components/Collect';
import LevelUp from './components/LevelUp';
import Avatars from './components/Avatars';
import BugBounty from './components/BugBounty';
import BlockedIp from './components/BlockedIp';
import ProvablyFair from './components/ProvablyFair';
import DiceBetDetails from './components/DiceBetDetails';
import Switch2FA from './components/Switch2FA';
import SecurityCodeSuccess from './components/SecurityCodeSuccess/SecurityCodeSuccess';
import VerifySecurityCode from './components/VerifySecurityCode/VerifySecurityCode';
import ChangePasswordSuccess from './components/ChangePasswordSucces/ChangePasswordSuccess';
import Lottery from './components/Lottery';
import TipsInfo from './components/TipsInfo';
import MinesVerify from './components/MinesVerify';
import DiceVerify from './components/DiceVerify';
import SupportInfo from './components/SupportInfo';
import LimboBetDetails from './components/LimboBetDetails';
import WheelFortune from './components/WheelOfFortune';
import GamesLimits from './components/GamesLimits';
import CancelEmailVerification from './components/CancelEmailVerification';
import HotKeys from './components/HotKeys';
import GamesRules from './components/GamesRules';
import Contacts from './components/Contacts';
import CoinBetDetails from './components/CoinBetDetails';
import MissionsStageGame from './components/MissionsStageGame';
import MissionsStagePrize from './components/MissionsStagePrize';
import MissionsInfo from './components/MissionsInfo';
import CoinflipVerify from './components/CoinflipVerify';
import KenoBetDetails from './components/KenoBetDetails';
import BonusLogin from './components/BonusLogin';
import BonusCancel from './components/BonusCancel';
import KenoVerify from './components/KenoVerify';
import BonusCollect from './components/BonusCollect';
import RakebackCollect from './components/RakebackCollect';
import RakebackInfo from './components/RakebackInfo';
import HotKeysBlackJack from './components/HotKeysBlackJack';
import BlackJackBetDetails from './components/BlackJackBetDetails';
import BlackjackVerify from './components/BlackjackVerify';
import LandingJoinTerms from './components/LandingJoinTerms';
import TexasHoldemRules from './components/TexasHoldemRules';
import TexasHoldemBetDetails from './components/TexasHoldemBetDetails';
import TexasHoldemVerify from './components/TexasHoldemVerify';
import PlinkoBetDetails from './components/PlinkoBetDetails';
import PlinkoVerify from './components/PlinkoVerify';
import DepositChanged from './components/DepositChanged';
import TotalCollectedInfo from './components/TotalCollectedInfo';
import DividendsCalculator from './components/DividendsCalculator';
import CryptoCurrencies from './components/CryptoCurrencies';
import RanksModalContainer from './components/Ranks';
import EventResult from './components/EventResults';

const PopUps: React.FC = () => {
  const { popUpsOpen, setPopUpsOpen } = useContext(ContextPopUps);
  const { modalOpen, data } = typeof popUpsOpen === 'string' ? { modalOpen: popUpsOpen, data: null } : popUpsOpen;
  const [currentEmail, setCurrentEmail] = useState(null);

  const handlePopUpsClose = () => {
    if (typeof data?.onClose === 'function') {
      data.onClose();
    } else {
      setPopUpsOpen(null);
    }
  };

  const popUpsDraw = useCallback(() => {
    switch (modalOpen) {
      case popUps.login:
        return <LogIn setCurrentEmail={setCurrentEmail} data={data} />;
      case popUps.verifySecurityCode:
        return <VerifySecurityCode data={data} currentEmail={currentEmail} />;
      case popUps.securityCodeSuccess:
        return <SecurityCodeSuccess data={data} />;
      case popUps.switch2FA:
        return <Switch2FA data={data} />;
      case popUps.forgotPassword:
        return <ForgotPassword />;
      case popUps.passwordSuccess:
        return <PasswordSuccess />;
      case popUps.changePasswordSuccess:
        return <ChangePasswordSuccess data={data} />;
      case popUps.collect:
        return <Collect data={data} />;
      case popUps.avatars:
        return <Avatars />;
      case popUps.levelUp:
        return <LevelUp data={data} />;
      case popUps.registration:
        return <SignUp setCurrentEmail={setCurrentEmail} />;
      case popUps.signUpConfirm:
        return <SignUpConfirm data={data} />;
      case popUps.cancelSignUpConfirm:
        return <CancelEmailVerification data={data} />;
      case popUps.bugBounty:
        return <BugBounty />;
      case popUps.provablyFair:
        return <ProvablyFair />;
      case popUps.blockedIp:
        return <BlockedIp />;
      case popUps.minesBetDetails:
        return <MinesBetDetails data={data} />;
      case popUps.diceBetDetails:
        return <DiceBetDetails data={data} />;
      case popUps.lottery:
        return <Lottery />;
      case popUps.tipsInfo:
        return <TipsInfo data={data} />;
      case popUps.diceVerify:
        return <DiceVerify />;
      case popUps.minesVerify:
        return <MinesVerify data={data} />;
      case popUps.coinflipVerify:
        return <CoinflipVerify />;
      case popUps.blackjackVerify:
        return <BlackjackVerify data={data} />;
      case popUps.walletNavigation:
        return <WalletNavigation data={data} />;
      case popUps.supportInfo:
        return <SupportInfo />;
      case popUps.limboBetDetails:
        return <LimboBetDetails data={data} />;
      case popUps.wheelFortune:
        return <WheelFortune />;
      case popUps.gamesLimits:
        return <GamesLimits data={data} />;
      case popUps.hotKeys:
        return <HotKeys data={data} />;
      case popUps.gamesRules:
        return <GamesRules data={data} />;
      case popUps.contacts:
        return <Contacts />;
      case popUps.coinBetDetails:
        return <CoinBetDetails data={data} />;
      case popUps.missionsStageGame:
        return <MissionsStageGame data={data} />;
      case popUps.missionsStagePrize:
        return <MissionsStagePrize data={data} />;
      case popUps.kenoBetDetails:
        return <KenoBetDetails data={data} />;
      case popUps.kenoVerify:
        return <KenoVerify />;
      case popUps.missionsInfo:
        return <MissionsInfo />;
      case popUps.bonusLogin:
        return <BonusLogin data={data} />;
      case popUps.bonusCancel:
        return <BonusCancel data={data} />;
      case popUps.bonusCollect:
        return <BonusCollect data={data} />;
      case popUps.rakebackCollect:
        return <RakebackCollect />;
      case popUps.rakebackInfo:
        return <RakebackInfo data={data} />;
      case popUps.hotKeysBlackJack:
        return <HotKeysBlackJack data={data} />;
      case popUps.blackJackBetDetails:
        return <BlackJackBetDetails data={data} />;
      case popUps.landingJoinTerms:
        return <LandingJoinTerms />;
      case popUps.texasHoldemRules:
        return <TexasHoldemRules />;
      case popUps.texasHoldemBetDetails:
        return <TexasHoldemBetDetails data={data} />;
      case popUps.texasHoldemVerify:
        return <TexasHoldemVerify data={data} />;
      case popUps.plinkoBetDetails:
        return <PlinkoBetDetails data={data} />;
      case popUps.plinkoVerify: {
        return <PlinkoVerify data={data} />;
      }
      case popUps.totalCollectedInfo: {
        return <TotalCollectedInfo data={data} />;
      }
      case popUps.depositChanged:
        return <DepositChanged />;
      case popUps.dividendsCalculator:
        return <DividendsCalculator />;
      case popUps.cryptoCurrencies: {
        return <CryptoCurrencies data={data} />;
      }
      case popUps.ranks:
        return <RanksModalContainer />;
      case popUps.eventResult: {
        return <EventResult data={data} />;
      }
      default:
        return null;
    }
  }, [modalOpen, data]);

  return (
    <PopUpsStyle fullwidth={data?.fullScreen ? 'true' : ''} config={data?.config || modalOpen}>
      <div className="popUps__blur" onClick={handlePopUpsClose} />
      <div className="popUps__content">
        <div onClick={handlePopUpsClose} className="popUps__btn">
          <img src={cancel} alt="icon" className="popUps__close" />
        </div>
        <div className="popUps__line" />
        {popUpsDraw()}
      </div>
    </PopUpsStyle>
  );
};

export default PopUps;
