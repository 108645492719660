import clsx from 'clsx';

import cashbackBg from 'assets/img/CashbackPage/cashback-bg.webp';

import { getRankImg } from 'func/prepareDataCashback';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';

import CashbackInfo from 'components/Pages/CashbackPage/components/CashbackInfo';
import Button from 'components/Base/Button';
import NotAuthCover from 'components/common/NotAuthCover';
import CashbackProgressBar from 'components/Pages/CashbackPage/components/CashbackProgressBar';

import styles from './styles.module.scss';
import { ICashbackSummary } from './types';

const CashbackSummary: React.FC<ICashbackSummary> = ({ rank, nextRank, progress, profile, onRanks }) => {
  const { translate } = useLocalization();

  return (
    <div className={styles.wrapper}>
      {profile ? <img className={styles.icon} src={cashbackBg} alt="background" /> : null}
      <div className={styles.header}>
        <h3>{translate('cashback.percent.title')}</h3>
        <CashbackInfo tooltipClassName={styles.tooltip} text={translate('cashback.percent.description')} />
      </div>
      <div className={styles.info}>
        <h4 className={styles.title}>{translate('cashback.summary.title')}</h4>
        <p className={styles.description}>{translate('cashback.summary.description')}</p>
      </div>
      <div className={styles.body}>
        <div className={styles.rankWrapper}>
          <img className={styles.rankImage} src={getRankImg(rank.id)} alt={`${rank.id} rank`} />
        </div>
        <div className={styles.progress}>
          <div className={styles.current}>
            <div>
              <p className={styles.subtitle}>{translate('cashback.summary.rank')}</p>
              <div className={styles.rankData}>
                <span className={styles.rankTitle}>
                  {translate('popups.rank.tooltip')} {rank.id}
                </span>
                {rank.vip ? <span className={styles.rankVip}>{translate('games.tabs.vip')}</span> : null}
              </div>
            </div>
            <div>
              <p className={styles.subtitle}>{translate('cashback.summary.cashback')}</p>
              <div className={styles.cashback}>
                <div className={clsx(styles.cashbackText, styles.currentPercentage)}>{rank.cashback}%</div>
                {nextRank ? (
                  <>
                    <div className={styles.cashbackText}>&gt;</div>
                    <div className={styles.cashbackText}>{nextRank.cashback}%</div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <div className={styles.buttons}>
            <Button
              className={styles.ranksButton}
              title={translate('cashback.summary.action')}
              variant={ButtonType.secondary}
              color="#2A3A5D"
              type="button"
              onclick={onRanks}
            />
          </div>
          <div className={styles.progressBarWrapper}>
            {profile ? (
              <CashbackProgressBar
                progressWidth={progress}
                currentWager={profile.totalWager}
                rank={rank.id}
                currentPercent={rank.cashback}
                nextCashback={nextRank?.cashback}
                wager={nextRank?.wager || '0'}
                percent={nextRank ? Number(nextRank.cashback) : 0}
              />
            ) : (
              <NotAuthCover className={styles.noShadow} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashbackSummary;
