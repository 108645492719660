import { pathOr } from 'ramda';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { useAppSelector } from 'hooks/useAppSelector';
import { userProfile } from 'store/user/user.selectors';
import { IRank } from 'types';
import { GET_RANKS } from 'graphQl/query/cashback/ranks';

import RanksModal from './ranks';

const RanksModalContainer: React.FC = () => {
  const [rank, setRank] = useState<IRank>();
  const [ranks, setRanks] = useState<IRank[]>([]);

  const profile = useAppSelector(userProfile);

  const { data } = useQuery(GET_RANKS, { fetchPolicy: 'cache-and-network' });

  useEffect(() => {
    if (data) {
      const ranks = pathOr<IRank[]>([], ['ranks'], data);
      const sortedRanks = [...ranks].sort((a, b) => a.id - b.id);

      setRanks(sortedRanks);
    }
  }, [data]);

  useEffect(() => {
    if (!ranks.length) return;

    if (profile) {
      const newRank = ranks.find((r) => r.id === profile.rank.id);

      setRank(newRank);
      return;
    }

    const [firstRank] = ranks;

    setRank(firstRank);
  }, [profile, ranks]);

  const handleChangeRank = (rankId: number) => {
    const newRank = ranks.find((r) => r.id === rankId);

    if (newRank) {
      setRank(newRank);
    }
  };

  return <RanksModal rank={rank} ranks={ranks} onChangeRank={handleChangeRank} />;
};

export default RanksModalContainer;
