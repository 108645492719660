/* eslint-disable @typescript-eslint/no-explicit-any */

import { IGeneralDataState } from './generalData.state';
import {
  ISetActiveTokens,
  ISetEventsList,
  ISetExchangeRates,
  ISetLotteryState,
  ISetRanksList,
  ISetSettings,
  ISetVipRank,
} from './generalData.types';

const generalDataReducer = {
  setVipRank: (state: IGeneralDataState, action: ISetVipRank): void => {
    state.vipRank = action.payload;
  },
  setRanksList: (state: IGeneralDataState, action: ISetRanksList): void => {
    state.ranksList = action.payload;
  },
  setEventsList: (state: IGeneralDataState, action: ISetEventsList): void => {
    state.eventsList = action.payload;
  },
  setExchangeRates: (state: IGeneralDataState, action: ISetExchangeRates): void => {
    state.exchangeRates = action.payload;
  },
  setLotteryState: (state: IGeneralDataState, action: ISetLotteryState): void => {
    state.lotteryState = action.payload;
  },
  setSettings: (state: IGeneralDataState, action: ISetSettings): void => {
    state.settings = action.payload;
  },
  setActiveTokens: (state: IGeneralDataState, action: ISetActiveTokens): void => {
    state.activeTokens = action.payload;
  },
};

export default generalDataReducer;
