import { createSlice } from '@reduxjs/toolkit';

import { userInitialState } from './user.state';
import userReducers from './user.reducer';

const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: userReducers,
});

export const { setUser, setToken, setStarTokenPercent } = userSlice.actions;
export default userSlice.reducer;
