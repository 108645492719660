import clsx from 'clsx';

import styles from './styles.module.scss';
import { ITableCell } from './types';

const TableCell: React.FC<ITableCell> = ({
  className = '',
  contentClassName = '',
  content,
  align = 'left',
  even,
  ...props
}) => {
  return (
    <td className={clsx(styles.cell, even ? styles.even : '', className)} align={align} {...props}>
      <div className={clsx(styles.content, contentClassName)}>{content}</div>
    </td>
  );
};

export default TableCell;
