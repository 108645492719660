import Big from 'big.js';

import approveIcon from 'assets/img/CashbackPage/approve.webp';
import declineIcon from 'assets/img/CashbackPage/decline.webp';
import cashbackIcon from 'assets/img/ranks/cash-back.svg';
import bankIcon from 'assets/img/ranks/piggy-bank.webp';
import bonusIcon from 'assets/img/ranks/bonus-rank.webp';
import userIcon from 'assets/img/CashbackPage/user.webp';
import coinIcon from 'assets/img/CashbackPage/coin.webp';
import boxIcon from 'assets/img/CashbackPage/box.webp';
import vipIcon from 'assets/img/CashbackPage/vip.webp';

import { useLocalization } from 'components/Internationalization';
import { getAmountWithoutZeroEnd, getImgSrc } from 'func/common';
import { ETokenCode } from 'components/constants/constants';

import RankSlider from './components/RankSlider';

import styles from './styles.module.scss';
import { IRanksModal } from './types';

const RanksModal: React.FC<IRanksModal> = ({ rank, ranks, onChangeRank }) => {
  const { translate } = useLocalization();

  return (
    <div className={styles.wrapper}>
      <div className={styles.gradient} />
      <div className={styles.content}>
        <h3 className={styles.title}>{translate('cashback.ranks.title')}</h3>
        <RankSlider className={styles.slider} rank={rank?.id || 1} ranks={ranks} onChangeRank={onChangeRank} />
        {rank ? (
          <>
            <p className={styles.subtitle}>{translate('cashback.ranks.requirements.text')}</p>
            <p className={styles.listItem}>
              <img src={getImgSrc(ETokenCode.BTC)} alt="BTC icon" />
              <span>{translate('cashback.ranks.wager.text')}</span>
              <span className={styles.white}>
                {getAmountWithoutZeroEnd(rank.wager)} {ETokenCode.BTC}
              </span>
            </p>
            <p className={styles.subtitle}>{translate('cashback.ranks.benefits.text')}</p>
            <ul className={styles.list}>
              {Big(rank.bonus).gt(0) ? (
                <li className={styles.listItem}>
                  <img src={bonusIcon} alt="Bonus icon" />
                  <span>{translate('cashback.ranks.bonus.text')}</span>
                  <span className={styles.white}>
                    {getAmountWithoutZeroEnd(rank.bonus)} {rank.displayName || ETokenCode.BTC}
                  </span>
                </li>
              ) : null}
              <li className={styles.listItem}>
                <img src={cashbackIcon} alt="Cashback icon" />
                <span>{translate('cashback.ranks.cashback.text')}</span>
                <span className={styles.white}>{rank.cashback}%</span>
              </li>
              {Big(rank.rakeback).gt(0) ? (
                <li className={styles.listItem}>
                  <img src={bankIcon} alt="Rakeback icon" />
                  <span>{translate('cashback.ranks.rakeback')}</span>
                  <span className={styles.white}>{rank.rakeback}%</span>
                </li>
              ) : null}
            </ul>
            <ul className={styles.listAdditional}>
              <li className={styles.listAdditionalItem}>
                <img src={rank.tips ? approveIcon : declineIcon} alt="Status Icon" />
                <img src={coinIcon} alt="Tips Icon" />
                <span>{translate('cashback.ranks.tips.text')}</span>
              </li>
              <li className={styles.listAdditionalItem}>
                <img src={rank.vip ? approveIcon : declineIcon} alt="Status Icon" />
                <img src={vipIcon} alt="Vip Icon" />
                <span>{translate('cashback.ranks.vip.status.text')}</span>
              </li>
              <li className={styles.listAdditionalItem}>
                <img src={rank.vault ? approveIcon : declineIcon} alt="Status Icon" />
                <img src={boxIcon} alt="Vault Icon" />
                <span>{translate('cashback.ranks.vault.text')}</span>
              </li>
              <li className={styles.listAdditionalItem}>
                <img src={rank.avatars ? approveIcon : declineIcon} alt="Status Icon" />
                <img src={userIcon} alt="Avatar Icon" />
                <span>{translate('cashback.ranks.premium.text')}</span>
              </li>
            </ul>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default RanksModal;
