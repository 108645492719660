import { ITableTh } from 'components/Base/NewTable/components/TableHead/types';
import { TranslationType } from 'types';

export const EVENT_RESULTS_ROWS_PER_PAGE = 10;
export const eventResultsRows = (t: TranslationType): ITableTh[] => [
  { id: 'event-results-place', title: '№', align: 'left' },
  { id: 'event-results-player', title: t('events.table.player'), align: 'left' },
  { id: 'event-results-wager', title: t('events.table.wager'), align: 'left' },
  { id: 'event-results-prize', title: t('events.table.prize'), align: 'left' },
];
