import { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';

import dividendsIcon from 'assets/img/DividendsPage/pool.webp';
import timerIcon from 'assets/img/DividendsPage/timer.svg';
import graphIcon from 'assets/img/DividendsPage/graph.webp';

import { useAppSelector } from 'hooks/useAppSelector';
import { userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';
import { DIVIDENDS_QUERIES_AUTH } from 'graphQl/query/dividends/dividendsQueriesAuth';
import { GET_DIVIDENDS_POOL } from 'graphQl/query/dividends/dividendsPool';
import { prepareDividendsPool, prepareDividendsPoolUSD } from 'func/prepareDataDividends';
import { amount, formatUsdAmount, getImgSrc } from 'func/common';
import { ETokenCode, popUps } from 'components/constants/constants';
import { TOTAL_DIVIDEND_PAID } from 'graphQl/query/dividends/dividend';
import { ContextPopUps } from 'context';

import DividendsTimer from '../DividendsTimer';

import styles from './styles.module.scss';
import DividendsInfo from '../DividendsInfo';
import { POOL_CURRENCIES } from './constants';

const DividendsPool: React.FC = () => {
  const token = useAppSelector(userToken);
  const { language, translate } = useLocalization();
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const [balanceData, setBalanceData] = useState([]);
  const [usdData, setUsdData] = useState(null);
  const [totalPaid, setTotalPaid] = useState('0');

  const [cardDescription, setCardDescription] = useState('');

  const [allDividends] = useLazyQuery(DIVIDENDS_QUERIES_AUTH);
  const {
    data,
    refetch: updateDividendPool,
    startPolling,
    stopPolling,
  } = useQuery(GET_DIVIDENDS_POOL, { fetchPolicy: 'cache-and-network' });

  const { data: totalData } = useQuery(TOTAL_DIVIDEND_PAID);

  useEffect(() => {
    if (data) {
      const usdAmount = prepareDividendsPoolUSD(data.dividendPool)[0].title;

      setBalanceData(prepareDividendsPool(data.dividendPool));
      setUsdData(formatUsdAmount(usdAmount));
    }
  }, [data]);

  useEffect(() => {
    if (totalData) {
      setTotalPaid(totalData.totalDividends);
    }
  }, [totalData]);

  useEffect(() => {
    const description = translate('dividends.pool.description');
    const newDescription = description.replace(':fee', '2%').replace(':remaining', '98%');

    setCardDescription(newDescription);
  }, [language]);

  useEffect(() => {
    startPolling(5000);
    return () => {
      stopPolling();
    };
  }, []);

  const handleOnTick = () => {
    if (token) {
      allDividends();
    }
    updateDividendPool();
  };

  const handleAllCurrencies = () => {
    setPopUpsOpen({ modalOpen: popUps.cryptoCurrencies, data: { dividends: balanceData } });
  };

  return (
    <div className={styles.wrapper}>
      <img className={styles.icon} src={dividendsIcon} alt="pool" />
      <div className={styles.header}>
        <h3>{translate('dividends.pool.title')}</h3>
        <DividendsInfo text={cardDescription} />
      </div>
      <div className={styles.body}>
        <div className={styles.pool}>
          <p>{translate('dividends.total.amount')}</p>
          <div className={styles.poolRow}>
            <img src={getImgSrc(ETokenCode.USD)} alt={ETokenCode.USD} />
            <h2>{usdData} USD</h2>
          </div>
          <div className={styles.totalHeader}>
            <h4>{translate('dividends.next.distribution')}</h4>
            <div className={styles.totalTimer}>
              <img src={timerIcon} alt="timer" />
              <DividendsTimer onTick={handleOnTick} />
            </div>
          </div>
          <div className={styles.currencyWrap}>
            <div className={styles.currenciesList}>
              {POOL_CURRENCIES.map((c) => (
                <img key={c} src={getImgSrc(c)} alt={c} />
              ))}
            </div>
            <button onClick={handleAllCurrencies}>{translate('dividends.view.crypto')}</button>
          </div>
        </div>
        <div className={styles.paid}>
          <p>{translate('dividends.total.paid')}</p>
          <div className={styles.paidAmount}>
            <img src={getImgSrc(ETokenCode.BTC)} alt={ETokenCode.BTC} />
            <h3>{amount(totalPaid)} BTC</h3>
          </div>
          <img className={styles.paidIcon} src={graphIcon} alt="graph" />
        </div>
      </div>
    </div>
  );
};

export default DividendsPool;
