import { IRank } from 'types';

export const DEFAULT_RANK: IRank = {
  id: 1,
  wager: '0',
  cashback: '0',
  bonus: '0',
  rakeback: '0',
  avatars: false,
  tips: false,
  vault: false,
  vip: false,
  displayName: '',
};
