import { useLocalization } from 'components/Internationalization';

import EventResultSelf from 'components/Base/Event/EventResultSelf';
import EventResultTable from 'components/Base/Event/EventResultTable';
import Loader from 'components/Base/Loader';

import styles from './styles.module.scss';
import { IEventResults } from './types';

const EventResults: React.FC<IEventResults> = ({ data, eventSelf, eventPlayers, loading, token }) => {
  const { translate } = useLocalization();

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>{translate('events.buttons.leaderboard')}</h2>
      {loading ? <Loader className={styles.loader} pages /> : null}
      {token ? (
        <EventResultSelf result={eventSelf} prizeCode={data.prizeCode} wagerTokenCode={data.wagerTokenCode} />
      ) : null}
      <EventResultTable results={eventPlayers} prizeCode={data.prizeCode} wagerTokenCode={data.wagerTokenCode} />
    </div>
  );
};

export default EventResults;
