import { createSlice } from '@reduxjs/toolkit';

import { dataInitialState } from './data.state';
import dataReducers from './data.reducer';

const dataSlice = createSlice({
  name: 'data',
  initialState: dataInitialState,
  reducers: dataReducers,
});

export const {
  setFavoriteGamesList,
  setUserBonuses,
  setIsDisplayBonus,
  setIsActiveUserBonus,
  setFreeSpinsSettings,
  setGames,
  setVipStatus,
  setActiveBonusTokens,
  setBonusMarketing,
  setUserBonusSpinsStatus,
} = dataSlice.actions;
export default dataSlice.reducer;
