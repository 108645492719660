import { ETokenCode } from 'components/constants/constants';

export const POOL_CURRENCIES = [
  ETokenCode.BTC,
  ETokenCode.ETH,
  ETokenCode.BNB,
  ETokenCode.TRX,
  ETokenCode.DOGE,
  ETokenCode.LTC,
  ETokenCode.XRP,
  ETokenCode.USDT,
];
