import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { ILazyLoadImg } from './types';

const LazyLoadImg: React.FC<ILazyLoadImg> = ({ alt, className, src, onClick, onError }) => {
  return (
    <LazyLoadImage
      alt={alt || 'icon'}
      src={src}
      effect="blur"
      className={className}
      wrapperClassName={className}
      onClick={onClick}
      onError={onError}
    />
  );
};

export default LazyLoadImg;
