import React from 'react';

import dividendsIcon from 'assets/img/PageTitles/Dividends_Icon.svg';

import { useLocalization } from 'components/Internationalization';

import PageTitle from 'components/Base/PageTitle';
import DividendsButtonLink from '../DividendsButtonLink';

import styles from './styles.module.scss';

const DividendsHeader: React.FC = () => {
  const { translate } = useLocalization();
  const title = translate('dividends.title');
  return (
    <div className={styles.wrapper}>
      <PageTitle
        className={styles.title}
        icon={dividendsIcon}
        title={title}
        lineColor="transparent"
        titleSize="small"
        largeImage
      />
      <DividendsButtonLink />
    </div>
  );
};

export default DividendsHeader;
