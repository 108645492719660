import clsx from 'clsx';
import { useContext } from 'react';

import lockIcon from 'assets/img/common/lock-colored.webp';

import { ButtonType } from 'components/Base/Button/types';
import { ContextPopUps } from 'context';
import { popUps } from 'components/constants/constants';
import { useLocalization } from 'components/Internationalization';

import Button from 'components/Base/Button';

import { INotAuthCover } from './types';
import styles from './styles.module.scss';

const NotAuthCover: React.FC<INotAuthCover> = ({ className = '' }) => {
  const { translate } = useLocalization();
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const handleLogIn = () => setPopUpsOpen(popUps.login);

  return (
    <div className={clsx(styles.wrapper, className)}>
      <img src={lockIcon} alt="lock" />
      <div className={styles.text}>
        <p>{translate('referral.not.auth.please')}</p>
        <h3>{translate('referral.not.auth.info')}</h3>
      </div>
      <Button
        title={translate('cards.not.auth.button')}
        width="fit-content"
        variant={ButtonType.primary}
        onClick={handleLogIn}
      />
    </div>
  );
};

export default NotAuthCover;
