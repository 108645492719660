import clsx from 'clsx';

import styles from './styles.module.scss';
import { ITableHead } from './types';

const TableHead: React.FC<ITableHead> = ({ head }) => {
  return (
    <thead className={styles.head}>
      <tr>
        {head.map(({ title: cellTitle, ...h }) => (
          <th {...h} key={h.id} className={clsx(styles.headCell, h.className)}>
            <div>{cellTitle}</div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;
