import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import mainIcon from 'assets/img/DividendsPage/token-details.webp';

import { useLocalization } from 'components/Internationalization';
import { GET_STAR_TOKENS } from 'graphQl/query/dividends/starTokens';

import DividendsInfo from '../DividendsInfo';

import styles from './styles.module.scss';

const DividendsTokenDetails: React.FC = () => {
  const { translate } = useLocalization();

  const [starToken, setStarToken] = useState(null);
  const { data: dataStarTokens } = useQuery(GET_STAR_TOKENS, {
    fetchPolicy: 'cache-only',
  });

  useEffect(() => {
    if (dataStarTokens) {
      setStarToken(dataStarTokens.starToken);
    }
  }, [dataStarTokens]);

  return (
    <div className={styles.wrapper}>
      <img className={styles.icon} src={mainIcon} alt="icon" />
      <div className={styles.body}>
        <div className={styles.titleWrap}>
          <h4>{translate('dividends.details.title')}</h4>
          <DividendsInfo text={translate('dividends.details.description')} />
        </div>
        <div className={styles.bodyItem}>
          <p>{translate('dividends.details.supply')}</p>
          <h5>{starToken ? Number(starToken.maxSupply).toLocaleString() : '0'}</h5>
        </div>
        <div className={styles.bodyItem}>
          <p>{translate('dividends.details.mined')}</p>
          <h5>{starToken ? Math.trunc(Number(starToken.totalMined)).toLocaleString() : '0'}</h5>
        </div>
        <div className={styles.bodyItem}>
          <p>{translate('dividends.details.staked')}</p>
          <h5>{starToken ? Math.trunc(Number(starToken.totalStaked)).toLocaleString() : '0'}</h5>
        </div>
        <div className={styles.bodyItem}>
          <p>{translate('dividends.details.burned')}</p>
          <h5>{starToken ? Number(starToken.totalBurned).toLocaleString() : '0'}</h5>
        </div>
      </div>
    </div>
  );
};

export default DividendsTokenDetails;
