import clsx from 'clsx';

import Pagination from 'components/Base/Pagination';
import TableHead from './components/TableHead';
import TableBody from './components/TableBody';

import styles from './styles.module.scss';
import { INewTable } from './types';

const NewTable: React.FC<INewTable> = ({
  className = '',
  wrapperClassName = '',
  head,
  rows,
  empty,
  total,
  perPage,
  page,
  onChangePage,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={clsx(styles.tableWrapper, wrapperClassName)}>
        <table className={clsx(styles.table, className)}>
          <TableHead head={head} />
          <TableBody rows={rows} empty={empty} />
        </table>
      </div>
      <Pagination
        className={styles.pagination}
        itemsLength={total}
        perPage={perPage}
        pageDisplayed={page}
        setCurrentPage={onChangePage}
        popUpTable
      />
    </div>
  );
};

export default NewTable;
