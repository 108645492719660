import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import mainIcon from 'assets/img/DividendsPage/estimated.webp';

import { useAppSelector } from 'hooks/useAppSelector';
import { userStarTokenPercent, userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';
import { ContextPopUps, ContextTokenCode } from 'context';
import { GET_DIVIDENDS_POOL } from 'graphQl/query/dividends/dividendsPool';
import { getEstimateDropdownType, handlePeriodCalculate } from 'func/prepareDataDividends';
import { EDividendsDropdown } from 'components/constants/dividends';
import { formatUsdAmount, getImgSrc } from 'func/common';
import { ETokenCode, popUps } from 'components/constants/constants';
import { ButtonType } from 'components/Base/Button/types';

import DropDown from 'components/Base/DropDown';
import Button from 'components/Base/Button';

import DividendsInfo from '../DividendsInfo';
import styles from './styles.module.scss';

const DividendsEstimated: React.FC = () => {
  const token = useAppSelector(userToken);
  const starTokenPercent = useAppSelector(userStarTokenPercent);
  const { language, translate } = useLocalization();
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const { tokenWithNetworksList } = useContext(ContextTokenCode);
  const [type, setType] = useState(null);
  const [estimateData, setEstimateData] = useState(null);
  const [dropdownInfo, setDropdownInfo] = useState([]);
  const [usdData, setUsdData] = useState(null);
  const [dropTitles, setDropTitles] = useState([]);

  // const [tokenData, setTokenData] = useState(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [balance, setBalance] = useState([]);

  useQuery(GET_DIVIDENDS_POOL, {
    onCompleted: (data) => {
      setEstimateData(data?.dividendPool);
    },
  });

  const handleDaily = () => handlePeriodCalculate(Number(starTokenPercent), 1, estimateData, setBalance, setUsdData);

  const handleMonthly = () => handlePeriodCalculate(Number(starTokenPercent), 30, estimateData, setBalance, setUsdData);
  // setBalance(amount(((Number(btcPercent) / 50) * percent * 30) / 100));   // origin formula

  const handleQuarterly = () =>
    handlePeriodCalculate(Number(starTokenPercent), 90, estimateData, setBalance, setUsdData);

  useEffect(() => {
    if (tokenWithNetworksList && dropTitles.length) {
      // const tokens = tokenWithNetworksList.tokenWithNetworks.filter((elem) => elem.tokenCode !== 'STAR');
      // setTokenData(tokens);
      const dropDownData = dropTitles.map((elem, index) => ({
        title: elem,
        func: () => setType(getEstimateDropdownType(index)),
      }));
      setDropdownInfo(dropDownData);
    }
  }, [tokenWithNetworksList, dropTitles]);

  useEffect(() => {
    if (estimateData && starTokenPercent) {
      switch (type) {
        case EDividendsDropdown.daily:
          return handleDaily();
        case EDividendsDropdown.monthly:
          return handleMonthly();
        case EDividendsDropdown.quarterly:
          return handleQuarterly();
        default:
          return handleDaily();
      }
    }
  }, [estimateData, starTokenPercent, type]);

  useEffect(() => {
    const dailyText = translate('dividends.estimated.daily');
    const monthlyText = translate('dividends.estimated.monthly');
    const quarterlyText = translate('dividends.estimated.quarterly');

    setDropTitles([dailyText, monthlyText, quarterlyText]);
  }, [language]);

  const handleCalculate = () => {
    setPopUpsOpen(popUps.dividendsCalculator);
  };

  return (
    <div className={styles.wrapper}>
      <img className={styles.icon} src={mainIcon} alt="icon" />
      <div className={styles.body}>
        <div className={styles.titleWrap}>
          <h4>{translate('dividends.estimated.title.desktop')}</h4>
          <DividendsInfo text={translate('dividends.estimated.description')} />
        </div>
        <DropDown data={dropdownInfo} width="100%" />
        <div className={styles.bodyItem}>
          <img src={getImgSrc(ETokenCode.USD)} alt={ETokenCode.USD} />
          <p>{formatUsdAmount(usdData?.length ? usdData[0]?.title : '0')} USD</p>
        </div>
        <div className={styles.buttonWrap}>
          <Button
            title={translate('dividends.estimated.flip.button.front')}
            variant={ButtonType.secondary}
            onClick={handleCalculate}
            disabled={!token}
          />
        </div>
      </div>
    </div>
  );
};

export default DividendsEstimated;
