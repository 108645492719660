import { useEffect, useState } from 'react';

import { useAppSelector } from 'hooks/useAppSelector';
import { userProfile } from 'store/user/user.selectors';
import { generalDataVipRank } from 'store/generalData/generalDtata.selectors';
import { checkIsVip } from 'func/vip';

import EventResultSelf from './eventResultSelf';
import { IEventResultSelfContainer } from './types';

const EventResultSelfContainer: React.FC<IEventResultSelfContainer> = (props) => {
  const [vip, setVip] = useState(false);

  const profile = useAppSelector(userProfile);
  const vipRank = useAppSelector(generalDataVipRank);

  useEffect(() => {
    if (!profile) return;

    const newVip = checkIsVip(vipRank, profile.rank.id);

    setVip(newVip);
  }, [profile, vipRank]);

  if (!profile) return;

  return <EventResultSelf vip={vip} profile={profile} {...props} />;
};

export default EventResultSelfContainer;
