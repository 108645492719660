import Big from 'big.js';

import collectAllIcon from 'assets/img/common/collectAllIcon.webp';

import { IExchangeRate, IRakeback, TranslationType } from 'types';
import { ICurrencyItem } from 'components/Base/CurrensiesTemplate/types';
import { IncomeArray } from 'components/Base/DropDown/types';
import { getDisplayName } from './prepareDataHeader';
import { amount, getImgSrc } from './common';

export const prepareRakebackCurrencies = (rakebacks: IRakeback[]): ICurrencyItem[] => {
  return rakebacks.map<ICurrencyItem>((r) => ({
    title: String(amount(r.availableBalance)),
    src: r.tokenCode,
    displayName: getDisplayName(r.tokenCode),
  }));
};

export const prepareRakebackCollectedCurrencies = (rakebacks: IRakeback[]): ICurrencyItem[] => {
  return rakebacks.map<ICurrencyItem>((r) => ({
    title: String(amount(r.totalCollected)),
    src: r.tokenCode,
    displayName: getDisplayName(r.tokenCode),
  }));
};

const getConvertedTokenAmountWithFee = (
  fromTokenCode: string,
  toTokenCode: string,
  balance: string,
  fee: number,
  exchangeRates: IExchangeRate[],
): string => {
  const exchangeRateFrom = exchangeRates.find((r) => r.token.tokenCode === fromTokenCode);
  const exchangeRateTo = exchangeRates.find((r) => r.token.tokenCode === toTokenCode);

  const rateFrom = exchangeRateFrom?.usdValue || '0';
  const rateTo = exchangeRateTo?.usdValue || '1';

  const rate = Big(rateFrom).div(rateTo).toFixed(8, Big.roundDown);
  const convertedAmount = Big(balance).mul(rate).toFixed(8, Big.roundDown);

  return Big(convertedAmount)
    .mul(1 - fee)
    .toFixed(8, Big.roundDown);
};

export const getConvertedTokenAmount = (
  rakebacks: IRakeback[],
  tokenCode: string,
  fee: string,
  exchangeRates: IExchangeRate[],
): string => {
  const conversionFee = Big(fee || '0')
    .div(100)
    .toNumber();
  const totalCollectionAmount = rakebacks.reduce<string>((result, r) => {
    const zeroBalance = Big(r.availableBalance).lte(0);

    if (zeroBalance) return result;

    const formattedAmount = String(amount(r.availableBalance, false, 8));

    if (r.tokenCode === tokenCode) {
      return Big(result).plus(formattedAmount).toFixed(8, Big.roundDown);
    }

    const convertedAmount = getConvertedTokenAmountWithFee(
      r.tokenCode,
      tokenCode,
      formattedAmount,
      conversionFee,
      exchangeRates,
    );

    return Big(result).plus(convertedAmount).toFixed(8, Big.roundDown);
  }, '0');

  return totalCollectionAmount;
};

export const prepareRakebackCurrenciesToCollect = (
  rakebacks: IRakeback[],
  exchangeRates: IExchangeRate[],
  fee: string,
  onChangeCollectedToken: (token: string) => void,
  translate: TranslationType,
): IncomeArray[] => {
  const currencies = rakebacks.map<IncomeArray>((r) => {
    const { tokenCode } = r;

    const convertedAmount = getConvertedTokenAmount(rakebacks, tokenCode, fee, exchangeRates);
    const formattedAmount = amount(convertedAmount);

    return {
      title: `${formattedAmount} ${getDisplayName(r.tokenCode)}`,
      tokenCode: r.tokenCode,
      img: getImgSrc(r.tokenCode),
      func: () => onChangeCollectedToken(r.tokenCode),
    };
  });

  currencies.unshift({
    title: translate('dividends.balance.dropdown.top.text'),
    img: collectAllIcon,
    func: () => onChangeCollectedToken(null),
  });

  return currencies;
};

export const prepareRakebackCurrenciesToCollectBonus = (
  rakebacks: IRakeback[],
  exchangeRates: IExchangeRate[],
  fee: string,
  onChangeCollectedToken: (token: string) => void,
  translate: TranslationType,
): ICurrencyItem[] => {
  const currencies = rakebacks.map<ICurrencyItem>((r) => {
    const { tokenCode } = r;

    const convertedAmount = getConvertedTokenAmount(rakebacks, tokenCode, fee, exchangeRates);
    const formattedAmount = amount(convertedAmount);

    return {
      title: `${formattedAmount} ${getDisplayName(r.tokenCode)}`,
      displayName: r.tokenCode,
      src: r.tokenCode,
      func: () => onChangeCollectedToken(r.tokenCode),
    };
  });

  return currencies;
};

export const calcRakebackUsdAmount = (rakebacks: IRakeback[], exchangeRates: IExchangeRate[]): string => {
  return rakebacks.reduce((acc, r) => {
    const exchangeRate = exchangeRates.find((er) => er.token.tokenCode === r.tokenCode);
    const usdValue = exchangeRate?.usdValue || '0';
    const tokenBalanceInUsd = Big(r.availableBalance).mul(usdValue);

    return Big(acc).plus(tokenBalanceInUsd).toFixed(10);
  }, '0');
};
