import React, { useEffect, useState } from 'react';
import AnimatedNumber from 'animated-number-react';

import { amount } from 'func/common';
import { getRanksImgSilver, getRankImg } from 'func/prepareDataCashback';
import { useLocalization } from 'components/Internationalization';

import ProgressBarStyle from './styles';
import ProgressBarCircle from './components/ProgressBarCircle';
import { ICashbackProgressBar } from './types';

const CashbackProgressBar: React.FC<ICashbackProgressBar> = ({
  progressWidth,
  wager,
  currentWager,
  rank,
  rankLine,
  percent,
  currentPercent,
  nextCashback,
}) => {
  const { translate } = useLocalization();

  const [count, setCount] = useState(0);

  useEffect(() => {
    if (progressWidth) {
      setCount(progressWidth);
    }
  }, [progressWidth]);

  return (
    <ProgressBarStyle progresswidth={progressWidth} rankline={rankLine ? 'true' : ''}>
      <div className="image-container">
        <div>
          {!rankLine ? (
            <img src={getRankImg(rank || 1)} alt="icon" />
          ) : (
            <div className="image-container--rank-percent">{`${currentPercent}%`}</div>
          )}
        </div>
        <div>{!rankLine && '25%'}</div>
        <div>{!rankLine && '50%'}</div>
        <div>{!rankLine && '75%'}</div>
        <div>
          {!rankLine ? (
            <img src={getRanksImgSilver(rank ? rank + 1 : 2)} alt="icon" />
          ) : (
            <div className="image-container--rank-percent">{`${percent}%`}</div>
          )}
        </div>
      </div>
      <div className="line-container">
        <div className="line">
          {progressWidth > 0 ? <ProgressBarCircle progresswidth={progressWidth} right="-2.7" bottom="-2.7" /> : null}
        </div>
        <ProgressBarCircle progresswidth={progressWidth} left="0" top="-50" start={0} />
        {!rankLine && <ProgressBarCircle progresswidth={progressWidth} left="25" top="-50" start={25} />}
        {!rankLine && <ProgressBarCircle progresswidth={progressWidth} left="48" top="-50" start={50} />}
        {!rankLine && <ProgressBarCircle progresswidth={progressWidth} left="73" top="-50" start={75} />}
        <ProgressBarCircle progresswidth={progressWidth} left="99" top="-50" start={99} />
      </div>
      <div className="description-wrap">
        {!rankLine ? (
          <div className="description-items-wrapper">
            <div className="description-item">
              <span>{translate('cashback.percent.card.wager')}:</span>
              <h3>{` ${amount(currentWager)} BTC `}</h3>
              <p>{` / ${wager || 0} BTC `} </p>
            </div>
            {nextCashback ? (
              <div className="description-item">
                <span>{translate('cashback.percent.card.nextCashback')}:</span>
                <h3>{nextCashback}%</h3>
              </div>
            ) : null}
          </div>
        ) : null}
        {rankLine && (
          <div className="description-item--progress">
            <AnimatedNumber
              value={count}
              formatValue={(value) => Math.trunc(value)}
              duration={3000}
              delay={2000}
              className="description-item--progress"
            />
            {`% ${translate('cashback.percent.card.progress')}`}
          </div>
        )}
      </div>
    </ProgressBarStyle>
  );
};

export default CashbackProgressBar;
