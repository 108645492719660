import clsx from 'clsx';

import { ReactComponent as InfoIcon } from 'assets/img/DividendsPage/info.svg';

import { ICashbackInfo } from './types';
import styles from './styles.module.scss';

const CashbackInfo: React.FC<ICashbackInfo> = ({ className = '', tooltipClassName = '', text }) => {
  return (
    <div className={clsx(styles.wrapper, className)}>
      {text ? <span className={clsx(styles.tooltip, tooltipClassName)}>{text}</span> : null}
      <InfoIcon className={styles.info} />
    </div>
  );
};

export default CashbackInfo;
