import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import planetIcon from 'assets/img/DividendsPage/Planet.svg';
import starsIcon from 'assets/img/DividendsPage/stars.svg';

import { GET_STAR_POOL, GET_STAR_POOL_HISTORY } from 'graphQl/query/dividends/starTokens';
import { useLocalization } from 'components/Internationalization';
import { amount, getDate, getImgSrc } from 'func/common';
import { ETokenCode } from 'components/constants/constants';

import DividendsInfo from '../DividendsInfo';
import styles from './styles.module.scss';
import { IStarHistory } from './types';

const DividendsStarPool: React.FC = () => {
  const { translate } = useLocalization();
  const [poolAmount, setPoolAmount] = useState('0');
  const [starPoolHistory, setStarPoolHistory] = useState<IStarHistory[]>(null);

  const { data: poolData, startPolling, stopPolling } = useQuery(GET_STAR_POOL, { fetchPolicy: 'cache-and-network' });

  const { data } = useQuery(GET_STAR_POOL_HISTORY, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setStarPoolHistory(data.starTokenBurn);
    },
  });

  useEffect(() => {
    if (data) {
      setStarPoolHistory(data.starTokenBurn);
    }
  }, [data]);

  useEffect(() => {
    if (poolData) {
      setPoolAmount(poolData.starTokenPool);
    }
  }, [poolData]);

  useEffect(() => {
    startPolling(60000);
    return () => {
      stopPolling();
    };
  }, []);

  return (
    <section className={styles.wrapper}>
      <div className={styles.pool}>
        <div className={styles.poolHeader}>
          <h4>{translate('dividends.star.pool.title')}</h4>
          <DividendsInfo text={translate('dividends.star.pool.description')} />
        </div>
        <div className={styles.poolAmount}>
          <p>{translate('dividends.total.amount')}</p>
          <div>
            <img src={getImgSrc(ETokenCode.STAR)} alt={ETokenCode.STAR} />
            <h3>{amount(poolAmount)} STARs</h3>
          </div>
        </div>
      </div>
      <div className={styles.historyWrap}>
        <h2>{translate('missions.table.titles.history')}</h2>
        <div className={styles.history}>
          <div className={styles.historyHeader}>
            <p>{translate('dividends.star.table.date')}</p>
            <p>{translate('dividends.star.table.burned')}</p>
          </div>
          {starPoolHistory
            ? starPoolHistory.map((el) => (
                <div className={styles.historyContent} key={el.id}>
                  <h4>{getDate(el.createdAt)}</h4>
                  <h4>
                    <img src={getImgSrc('STAR')} alt="star" />
                    {amount(el.amount)} STARs
                  </h4>
                </div>
              ))
            : null}
        </div>
      </div>
      <img src={planetIcon} alt="icon" className={styles.poolPlanet} />
      <img src={starsIcon} alt="icon" className={styles.poolStars} />
    </section>
  );
};

export default DividendsStarPool;
