import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { pathOr } from 'ramda';

import rewardIcon from 'assets/img/BonusPage/cashback.webp';

import { useAppSelector } from 'hooks/useAppSelector';
import { ContextPopUps } from 'context';
import { GET_MY_REFERRALS_BALANCE } from 'graphQl/query/referral/myReferrals';
import { prepareReferralBalance, prepareReferralTotalCollected } from 'func/prepareDataReferral';
import { ETokenCode, popUps, SettingName } from 'components/constants/constants';
import { COLLECT_REFERRAL_REWARD } from 'graphQl/mutations/referral/referralToWallet';
import { GET_REFERRAL_COLLECTION } from 'graphQl/query/referral/referralCollection';
import { getCommonSettingsValue, removeComas } from 'func/common';
import { handleDisabledCollectButton, prepareAffiliateBalance } from 'func/prepareDataAffiliates';
import { GET_COMMON_SETTINGS } from 'graphQl/query/settings/bonusSettings';
import { bigOrEqual } from 'func/prepareDataDice';
import { userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';
import { EXCHANGE_RATES } from 'graphQl/query/exchangeRate/exchangeRate';
import { IExchangeRate } from 'types';

import BalanceCard from 'components/common/BalanceCard';
import { totalAvailableCurrencies, totalCollectedCurrencies, totalCollectedUsd } from 'func/prepareDataBonus';

const ReferralBalanceNew: React.FC = () => {
  const token = useAppSelector(userToken);
  const { translate, language } = useLocalization();

  const { setPopUpsOpen } = useContext(ContextPopUps);

  const [balanceData, setBalanceData] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [collectedData, setCollectedData] = useState([]);
  const [collectToken, setCollectToken] = useState(null);
  const [convertedBalance, setConvertedBalance] = useState([]);
  const [fee, setFee] = useState('0');
  const [cardDescription, setCardDescription] = useState('');
  const [exchangeRates, setExchangeRates] = useState<IExchangeRate[]>([]);
  const [totalBalances, setTotalBalances] = useState('0');
  const [totalCollected, setTotalCollected] = useState('0');

  const [collectReferralReward] = useMutation(COLLECT_REFERRAL_REWARD, {
    refetchQueries: [GET_REFERRAL_COLLECTION, GET_MY_REFERRALS_BALANCE],
  });

  const { data: dataSettings } = useQuery(GET_COMMON_SETTINGS, {
    fetchPolicy: 'cache-only',
  });

  const { data: referralBalanceResponse } = useQuery(GET_MY_REFERRALS_BALANCE, {
    fetchPolicy: 'cache-and-network',
    skip: !token,
  });

  const { data: exchangeRatesData } = useQuery(EXCHANGE_RATES, { fetchPolicy: 'cache-and-network' });

  const handleCollect = async () => {
    setDisabled(true);
    try {
      await collectReferralReward({
        variables: {
          input: {
            tokenCode: collectToken,
          },
        },
      });
      const starBalance = balanceData.find((el) => el.src === ETokenCode.STAR);
      const popUpData = convertedBalance.find((el) => el.src === collectToken);
      const isDisplayStar = bigOrEqual(removeComas(starBalance.title), '0.00000001');
      const specificList = isDisplayStar ? [popUpData, starBalance] : [popUpData];
      setPopUpsOpen({
        modalOpen: popUps.collect,
        data: collectToken ? specificList : balanceData,
      });
      setCollectToken(null);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('[COLLECT_REFERRAL_REWARD_ERROR]: ', error);
    }
  };

  useEffect(() => {
    if (referralBalanceResponse) {
      const { myReferralBalances, convertedReferralAvailableCollectionBalance } = referralBalanceResponse;
      const converted = prepareAffiliateBalance(convertedReferralAvailableCollectionBalance);
      const totalCurrencies = totalAvailableCurrencies(myReferralBalances, exchangeRates);
      const totalCollectedCurrency = totalCollectedCurrencies(myReferralBalances, exchangeRates);
      const totalBalance = totalCollectedUsd(totalCurrencies);
      const totalCollect = totalCollectedUsd(totalCollectedCurrency);

      setConvertedBalance(converted);
      setBalanceData(prepareReferralBalance(myReferralBalances));
      setCollectedData(prepareReferralTotalCollected(myReferralBalances));
      setTotalBalances(totalBalance);
      setTotalCollected(totalCollect);
    }
  }, [referralBalanceResponse, exchangeRates]);

  useEffect(() => {
    if (exchangeRatesData) {
      const newExchangeRates = pathOr<IExchangeRate[]>([], ['exchangeRates'], exchangeRatesData);

      setExchangeRates(newExchangeRates);
    }
  }, [exchangeRatesData]);

  useEffect(() => {
    if (dataSettings) {
      setFee(getCommonSettingsValue(dataSettings.getSettings, SettingName.conversionFee));
    }
  }, [dataSettings]);

  useEffect(() => {
    if (!collectToken && convertedBalance) {
      const starBalance = balanceData.find((el) => el.src === ETokenCode.STAR);
      handleDisabledCollectButton(convertedBalance, setDisabled);
      if (starBalance?.title) {
        const isMoney = bigOrEqual(starBalance.title, '0.00000001');
        if (isMoney) {
          setDisabled(false);
        }
      }
    }
    if (collectToken && convertedBalance) {
      handleDisabledCollectButton(convertedBalance, setDisabled, collectToken);
    }
  }, [convertedBalance, collectToken, balanceData]);

  useEffect(() => {
    const description = translate('referral.balance.description');
    const newDescription = description.replace(':fee', `${fee}%`);

    setCardDescription(newDescription);
  }, [language, fee]);

  return (
    <BalanceCard
      icon={rewardIcon}
      title={translate('referral.balance.title')}
      description={cardDescription}
      balanceUsd={totalBalances}
      collectedUsd={totalCollected}
      balances={balanceData}
      collected={collectedData}
      disabled={disabled}
      convertedBalances={convertedBalance}
      onCollect={handleCollect}
      onToken={setCollectToken}
    />
  );
};

export default ReferralBalanceNew;
