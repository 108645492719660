import { useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { pathOr } from 'ramda';

import { useAppSelector } from 'hooks/useAppSelector';
import { useLocalization } from 'components/Internationalization';
import { ContextPopUps } from 'context';
import { GET_COMMON_SETTINGS } from 'graphQl/query/settings/bonusSettings';
import { GET_DIVIDENDS_BALANCE_DATA } from 'graphQl/query/dividends/dividend';
import { DIVIDENDS_TO_WALLET } from 'graphQl/mutations/dividends/dividendsToWallet';
import { GET_DIVIDENDS_COLLECTIONS } from 'graphQl/query/dividends/dividendsCillections';
import { getCommonSettingsValue } from 'func/common';
import { popUps, SettingName } from 'components/constants/constants';
import { prepareDividend, prepareDividendTotalCollected } from 'func/prepareDataDividends';
import { handleDisabledCollectButton, prepareAffiliateBalance } from 'func/prepareDataAffiliates';
import { userToken } from 'store/user/user.selectors';
import { totalCollectedCurrencies, totalCollectedUsd, totalAvailableCurrencies } from 'func/prepareDataBonus';
import { IExchangeRate } from 'types';
import { EXCHANGE_RATES } from 'graphQl/query/exchangeRate/exchangeRate';

import dividendsIcon from 'assets/img/BonusPage/dividends.webp';

import BalanceCard from 'components/common/BalanceCard';

const DividendsBalance: React.FC = () => {
  const token = useAppSelector(userToken);
  const { language, translate } = useLocalization();

  const { setPopUpsOpen } = useContext(ContextPopUps);

  const [balanceData, setBalanceData] = useState([]);
  const [collectedData, setCollectedData] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [collectToken, setCollectToken] = useState(null);
  const [convertedBalance, setConvertedBalance] = useState([]);
  const [fee, setFee] = useState('0');
  const [description, setDescription] = useState('');
  const [exchangeRates, setExchangeRates] = useState<IExchangeRate[]>([]);
  const [totalBalances, setTotalBalances] = useState('0');
  const [totalCollected, setTotalCollected] = useState('0');

  const { data: dataSettings } = useQuery(GET_COMMON_SETTINGS, {
    fetchPolicy: 'cache-only',
  });

  const { data: dividendsBalanceData } = useQuery(GET_DIVIDENDS_BALANCE_DATA, {
    fetchPolicy: 'cache-and-network',
    skip: !token,
  });

  const [collectDividend] = useMutation(DIVIDENDS_TO_WALLET, {
    refetchQueries: [GET_DIVIDENDS_BALANCE_DATA, GET_DIVIDENDS_COLLECTIONS],
  });

  const { data: exchangeRatesData } = useQuery(EXCHANGE_RATES, { fetchPolicy: 'cache-and-network' });

  useEffect(() => {
    if (dataSettings) {
      setFee(getCommonSettingsValue(dataSettings.getSettings, SettingName.conversionFee));
    }
  }, [dataSettings]);

  useEffect(() => {
    if (exchangeRatesData) {
      const newExchangeRates = pathOr<IExchangeRate[]>([], ['exchangeRates'], exchangeRatesData);

      setExchangeRates(newExchangeRates);
    }
  }, [exchangeRatesData]);

  useEffect(() => {
    if (dividendsBalanceData && language && exchangeRates.length) {
      const { dividend, totalDividendCollection, convertedDividendAvailableCollectionBalance } = dividendsBalanceData;

      const converted = prepareAffiliateBalance(convertedDividendAvailableCollectionBalance);
      const totalCurrencies = totalAvailableCurrencies(dividend, exchangeRates);
      const totalCollectedCurrency = totalCollectedCurrencies(totalDividendCollection, exchangeRates);
      const totalBalance = totalCollectedUsd(totalCurrencies);
      const totalCollect = totalCollectedUsd(totalCollectedCurrency);

      setBalanceData(prepareDividend(dividend));
      setCollectedData(prepareDividendTotalCollected(totalDividendCollection));
      setConvertedBalance(converted);
      setTotalBalances(totalBalance);
      setTotalCollected(totalCollect);
    }
  }, [dividendsBalanceData, language, exchangeRates]);

  useEffect(() => {
    if (!collectToken && convertedBalance) {
      handleDisabledCollectButton(convertedBalance, setDisabled);
    }
    if (collectToken && convertedBalance) {
      handleDisabledCollectButton(convertedBalance, setDisabled, collectToken);
    }
  }, [convertedBalance, collectToken]);

  useEffect(() => {
    const subtitle = translate('dividends.balance.description');
    const newSubtitle = subtitle.replace(':fee', `${fee}%`);

    setDescription(newSubtitle);
  }, [fee, language]);

  const handleCollect = async () => {
    setDisabled(true);
    try {
      await collectDividend({
        variables: {
          input: {
            tokenCode: collectToken,
          },
        },
      });
      const popUpData = convertedBalance.find((el) => el.src === collectToken);
      setPopUpsOpen({
        modalOpen: popUps.collect,
        data: collectToken ? [popUpData] : balanceData,
      });
      setCollectToken(null);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('[COLLECT_DIVIDEND_ERROR]: ', error);
    }
  };

  return (
    <BalanceCard
      icon={dividendsIcon}
      title={translate('dividnes.balance.card.title')}
      description={description}
      balanceUsd={totalBalances}
      collectedUsd={totalCollected}
      balances={balanceData}
      collected={collectedData}
      disabled={disabled}
      convertedBalances={convertedBalance}
      onCollect={handleCollect}
      onToken={setCollectToken}
    />
  );
};

export default DividendsBalance;
