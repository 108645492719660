import { useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';

import mainIcon from 'assets/img/DividendsPage/mining-price.webp';

import { useAppSelector } from 'hooks/useAppSelector';
import { userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';
import { ContextTokenCode } from 'context';
import { GET_MINING_STAGE, GET_TOKEN_MINING_PRICES } from 'graphQl/query/dividends/miningStage';
import { GET_STAR_TOKENS } from 'graphQl/query/dividends/starTokens';
import { GET_COMMON_SETTINGS } from 'graphQl/query/settings/bonusSettings';
import { getTokenCodeAcceptStar } from 'func/prepareDataDividends';
import { amount, getCommonSettingsValue, getImgSrc } from 'func/common';
import { SettingName } from 'components/constants/constants';

import DividendsInfo from '../DividendsInfo';
import styles from './styles.module.scss';

// import { GET_USER } from 'graphQl/query/auth/profile';
// import { getCommonSettingsValue } from 'func/common';
// import { SettingName } from 'components/constants/constants';

const DividendsMiningPrice: React.FC = () => {
  const token = useAppSelector(userToken);
  const { translate, language } = useLocalization();

  const { tokenCode } = useContext(ContextTokenCode);

  const [tokenPriceData, setTokenPriceData] = useState(null);
  const [miningPrice, setMiningPrice] = useState('0');
  const [displayDiscount, setDisplayDiscount] = useState(0);
  // const [starToken, setStarToken] = useState(null);
  // const [stage, setStage] = useState(null);

  const [description, setDescription] = useState('');

  const { data: dataMiningStage } = useQuery(GET_MINING_STAGE, {
    fetchPolicy: 'cache-only',
  });

  const { data: dataStarTokens } = useQuery(GET_STAR_TOKENS, {
    fetchPolicy: 'cache-only',
  });

  const { data: dataPrice } = useQuery(GET_TOKEN_MINING_PRICES, {
    fetchPolicy: 'cache-only',
  });

  const { data: dataSettings } = useQuery(GET_COMMON_SETTINGS, {
    fetchPolicy: 'cache-only',
  });

  // const { data: user } = useQuery(GET_USER, { fetchPolicy: 'cache-only' });

  useEffect(() => {
    if (dataPrice) {
      setTokenPriceData(dataPrice.tokenMiningPrices);
    }
    // if (dataMiningStage) {
    //   setStage(dataMiningStage.miningStage);
    // }
    // if (dataStarTokens) {
    //   setStarToken(dataStarTokens.starToken);
    // }
    if (dataSettings) {
      const discount = getCommonSettingsValue(dataSettings.getSettings, SettingName.miningDiscount);
      setDisplayDiscount(Number(discount));
    }
  }, [dataMiningStage, dataStarTokens, dataPrice, dataSettings]);

  useEffect(() => {
    if (tokenPriceData && tokenCode) {
      const mining = tokenPriceData.find((elem) => elem.tokenCode === getTokenCodeAcceptStar(token, tokenCode.token));
      setMiningPrice(mining?.miningPrice || '0');
    }
  }, [tokenCode, token, tokenPriceData]);

  // useEffect(() => {
  //   if (starToken && stage?.rangeStart) {
  //     setNextStageAmount(starToken?.totalMined - stage?.rangeStart);
  //   }
  // }, [starToken, user, stage]);

  useEffect(() => {
    const descriptionText = translate('dividends.mining.description');
    const newDescription = descriptionText.replace(':token', getTokenCodeAcceptStar(token, tokenCode.name));

    setDescription(newDescription);
  }, [language, tokenCode, token]);

  return (
    <div className={styles.wrapper}>
      <img className={styles.icon} src={mainIcon} alt="icon" />
      <div className={styles.body}>
        <div className={styles.titleWrap}>
          <div className={styles.titleItem}>
            <h4>{translate('dividends.mining.title')}</h4>
            <DividendsInfo text={description} />
          </div>
          {displayDiscount ? (
            <div className={styles.discount}>
              <p>
                {displayDiscount} % {translate('dividends.mining.discount')}
              </p>
            </div>
          ) : null}
        </div>
        <div className={styles.bodyItem}>
          <p>{translate('dividends.mining.table')}</p>
          <div>
            <img src={getImgSrc(tokenCode.name)} alt={tokenCode.name} />
            <h5>{String(amount(Number(miningPrice) * 2))}</h5>
          </div>
        </div>
        <div className={styles.bodyItem}>
          <p>{translate('dividends.mining.live')}</p>
          <div>
            <img src={getImgSrc(tokenCode.name)} alt={tokenCode.name} />
            <h5>{String(amount(Number(miningPrice) * 2))}</h5>
          </div>
        </div>
        <div className={styles.bodyItem}>
          <p>{translate('dividends.mining.all')}</p>
          <div>
            <img src={getImgSrc(tokenCode.name)} alt={tokenCode.name} />
            <h5>{String(amount(miningPrice))}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DividendsMiningPrice;
