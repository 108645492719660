import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import mainIcon from 'assets/img/DividendsPage/star-tokens.webp';

import { useAppSelector } from 'hooks/useAppSelector';
import { userStarTokenPercent, userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';
import { GET_STAR_TOKENS } from 'graphQl/query/dividends/starTokens';
import { GET_WALLET } from 'graphQl/query/wallet/wallet';
import { amount, BNDivide, getImgSrc } from 'func/common';
import { ETokenCode } from 'components/constants/constants';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setStarTokenPercent } from 'store/user';

import DividendsInfo from '../DividendsInfo';
import styles from './styles.module.scss';

const DividendsStarTokens: React.FC = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(userToken);
  const starTokenPercent = useAppSelector(userStarTokenPercent);
  const { translate } = useLocalization();

  const [balanceData, setBalanceData] = useState(null);
  const [starToken, setStarToken] = useState(null);

  const { data: dataStarTokens } = useQuery(GET_STAR_TOKENS, {
    skip: !token,
    fetchPolicy: 'cache-only',
  });
  useQuery(GET_WALLET, {
    skip: !token,
    variables: { input: 'STAR' },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setStarToken(data.wallet.availableBalance.toString().slice(0, 10));
    },
  });

  const getPercent = (percentFix) => percentFix.toFixed(4).replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1');

  useEffect(() => {
    if (dataStarTokens) {
      setBalanceData(dataStarTokens.starToken);
    }
  }, [dataStarTokens]);

  useEffect(() => {
    if (starToken && balanceData) {
      if (+starToken >= 10) {
        const percent = Number(BNDivide(starToken, Number(balanceData.totalStaked))) * 100;
        dispatch(setStarTokenPercent(percent));
      }
      if (+starToken < 10) {
        dispatch(setStarTokenPercent(0));
      }
    }
  }, [balanceData, starToken]);

  return (
    <div className={styles.wrapper}>
      <img className={styles.icon} src={mainIcon} alt="icon" />
      <div className={styles.body}>
        <div className={styles.titleWrap}>
          <h4>{translate('dividends.myStar.title')}</h4>
          <DividendsInfo text={translate('dividends.myStar.description')} />
        </div>
        <div className={styles.bodyItem}>
          <p>Stars</p>
          <div>
            <img src={getImgSrc(ETokenCode.STAR)} alt="icon" />
            <h5>{starToken ? String(amount(starToken)) : '0.00000000'}</h5>
          </div>
        </div>
        <div className={styles.bodyItem}>
          <p>% {translate('dividends.myStar.total')}</p>
          <h6>{starTokenPercent ? String(getPercent(starTokenPercent)) + ' %' : '0 %'}</h6>
        </div>
      </div>
    </div>
  );
};

export default DividendsStarTokens;
