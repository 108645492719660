import Big from 'big.js';
import { pathOr } from 'ramda';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { useAppSelector } from 'hooks/useAppSelector';
import { userProfile } from 'store/user/user.selectors';
import { ContextPopUps } from 'context';
import { GET_RANKS } from 'graphQl/query/cashback/ranks';
import { IRank } from 'types';
import { popUps } from 'components/constants/constants';

import { DEFAULT_RANK } from './constants';
import CashbackSummary from './cashbackSummary';

const CashbackSummaryContainer: React.FC = () => {
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const [rank, setRank] = useState<IRank>(DEFAULT_RANK);
  const [nextRank, setNextRank] = useState<IRank>();
  const [progress, setProgress] = useState(0);
  const [ranks, setRanks] = useState<IRank[]>([]);

  const profile = useAppSelector(userProfile);

  const { data } = useQuery(GET_RANKS, { fetchPolicy: 'cache-and-network' });

  useEffect(() => {
    if (data) {
      const ranks = pathOr<IRank[]>([], ['ranks'], data);
      const sortedRanks = [...ranks].sort((a, b) => a.id - b.id);

      setRanks(sortedRanks);
    }
  }, [data]);

  useEffect(() => {
    if (profile && ranks.length) {
      const rankIds = ranks.map((r) => r.id);

      const newLastRankId = Math.max(...rankIds);
      const newRank = ranks.find((r) => r.id === profile.rank.id);
      const newNextRank = profile.rank.id < newLastRankId ? ranks.find((r) => r.id > profile.rank.id) : undefined;

      if (newNextRank) {
        const newProgress = Big(profile.totalWager).mul(100).div(newNextRank.wager).toNumber().toFixed(2);

        setProgress(Number(newProgress));
      }

      setRank(newRank);
      setNextRank(newNextRank);
    }
  }, [profile, ranks]);

  const handleRanks = () => {
    setPopUpsOpen(popUps.ranks);
  };

  return (
    <CashbackSummary rank={rank} nextRank={nextRank} progress={progress} profile={profile} onRanks={handleRanks} />
  );
};

export default CashbackSummaryContainer;
