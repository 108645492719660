import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { amount, getImgSrc } from 'func/common';
import { ETokenCode } from 'components/constants/constants';

import styles from './styles.module.scss';
import { ITableWager } from './types';

const TableWager: React.FC<ITableWager> = ({
  className = '',
  amount: initialAmount,
  showCurrency = true,
  tokenCode = ETokenCode.BTC,
  decimals,
  comas,
  iconSize,
  format,
}) => {
  const [formattedAmount, setFormattedAmount] = useState<string | number>('0.00000000');

  useEffect(() => {
    if (format) {
      const newAmount = format(initialAmount);

      return setFormattedAmount(newAmount);
    }

    const newAmount = amount(initialAmount, comas, decimals);

    setFormattedAmount(newAmount);
  }, [initialAmount, decimals, comas, format]);

  return (
    <div className={clsx(styles.wrapper, className)}>
      <img
        className={styles.icon}
        style={{ width: iconSize?.width, height: iconSize?.height }}
        src={getImgSrc(tokenCode)}
      />
      <p className={styles.wager}>
        {formattedAmount} {showCurrency ? tokenCode : ''}
      </p>
    </div>
  );
};

export default TableWager;
