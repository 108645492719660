import { useEffect, useState } from 'react';

import { ITableRow } from 'components/Base/NewTable/components/TableRow/types';
import { useLocalization } from 'components/Internationalization';

import NewTable from 'components/Base/NewTable';

import { IEventResultTable } from './types';
import { eventResultsRows, EVENT_RESULTS_ROWS_PER_PAGE } from './constants';
import { prepareEventResultsRows } from './utils';

const EventResultTable: React.FC<IEventResultTable> = ({ results, prizeCode, wagerTokenCode }) => {
  const { translate } = useLocalization();

  const [page, setPage] = useState(1);
  const [rows, setRows] = useState<ITableRow[]>([]);
  const [paginatedRows, setPaginatedRows] = useState<ITableRow[]>([]);

  useEffect(() => {
    const newRows = prepareEventResultsRows(results, wagerTokenCode, prizeCode);

    setRows(newRows);
  }, [results]);

  useEffect(() => {
    const offset = (page - 1) * EVENT_RESULTS_ROWS_PER_PAGE;
    const limit = offset + EVENT_RESULTS_ROWS_PER_PAGE;

    const newPaginatedRows = rows.slice(offset, limit);

    setPaginatedRows(newPaginatedRows);
  }, [rows, page]);

  const handleChangePage = (offset: number) => {
    const page = Math.floor(offset / EVENT_RESULTS_ROWS_PER_PAGE + 1);

    setPage(page);
  };

  return (
    <NewTable
      head={eventResultsRows(translate)}
      rows={paginatedRows}
      total={results.length}
      perPage={EVENT_RESULTS_ROWS_PER_PAGE}
      page={page}
      onChangePage={handleChangePage}
    />
  );
};

export default EventResultTable;
