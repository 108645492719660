import { gql } from '@apollo/client';

export const CASHBACK_QUERIES_AUTH = gql`
  query allCashback {
    cashbackCollections {
      amount
      createdAt
      token {
        tokenCode
        displayName
      }
    }
    myCashbacks {
      availableBalance
      token {
        tokenCode
        displayName
      }
      totalCollected
    }
  }
`;
