import { gql } from '@apollo/client';

export const WEEKLY_WAGER = gql`
  query userCurrentWeeklyWager {
    userCurrentWeeklyWager
  }
`;

export const USER_PREFERENCES = gql`
  query myPreferences {
    myPreferences {
      language
    }
  }
`;
