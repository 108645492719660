import React from 'react';

import referralIcon from 'assets/img/PageTitles/Referral_Icon.svg';

import { useLocalization } from 'components/Internationalization';

import PageTitle from 'components/Base/PageTitle';
import ReferralButtonLink from '../ReferralButtonLink';

import styles from './styles.module.scss';

const ReferralHeader: React.FC = () => {
  const { translate } = useLocalization();

  return (
    <div className={styles.wrapper}>
      <PageTitle
        className={styles.title}
        icon={referralIcon}
        title={translate('referral.title')}
        lineColor="transparent"
        titleSize="small"
        largeImage
      />
      <ReferralButtonLink />
    </div>
  );
};

export default ReferralHeader;
