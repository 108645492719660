import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';

import { ContextPopUps } from 'context';
import { useAppSelector } from 'hooks/useAppSelector';
import { generalDataVipRank } from 'store/generalData/generalDtata.selectors';
import { checkIsVip } from 'func/common';
import { popUps } from 'components/constants/constants';

import Avatar from 'components/Base/Avatar';

import styles from './styles.module.scss';
import { ITablePlayer } from './types';

const TablePlayer: React.FC<ITablePlayer> = ({ className = '', avatar, rank = 1, alias }) => {
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const [vip, setVip] = useState(false);

  const vipRank = useAppSelector(generalDataVipRank);

  useEffect(() => {
    const newVip = checkIsVip(vipRank, rank);

    setVip(newVip);
  }, [rank, vipRank]);

  const handleTips = () => {
    setPopUpsOpen({
      modalOpen: popUps.tipsInfo,
      data: {
        alias,
        avatar,
        rank,
      },
    });
  };

  return (
    <div className={clsx(styles.player, className)} onClick={handleTips}>
      <Avatar className={styles.avatar} userAvatar={avatar} vip={vip} />
      <p className={styles.alias}>{alias}</p>
    </div>
  );
};

export default TablePlayer;
