import React, { useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';

import { useAppSelector } from 'hooks/useAppSelector';
import { DIVIDENDS_QUERIES_AUTH } from 'graphQl/query/dividends/dividendsQueriesAuth';
import { DIVIDENDS_QUERIES_NOT_AUTH } from 'graphQl/query/dividends/dividendsQueriesNotAuth';
import { userToken } from 'store/user/user.selectors';

import DocumentTitle from 'components/Base/DocumentTitle';

import { DOCUMENT_TITLE_SETTINGS } from 'components/constants/constants';
import DividendsTable from './components/DividendsTable';
import DividendsHeader from './components/DividendsHeader';
import DividendsPool from './components/DividendsPool';
import DividendsBalance from './components/DividendsBalance';
import DividendsStarPool from './components/DividendsStarPool';
import DividendsTokenDetails from './components/DividendsTokenDetails';
import DividendsMiningPrice from './components/DividendsMiningPrice';
import DividendsStarTokens from './components/DividendsStarTokens';
import DividendsEstimated from './components/DividendsEstimated';

import styles from './styles.module.scss';

const PreRegistrationPage: React.FC = () => {
  const token = useAppSelector(userToken);

  useQuery(DIVIDENDS_QUERIES_AUTH, {
    fetchPolicy: 'cache-and-network',
    skip: !token,
  });
  const [allDividendsNotAuth] = useLazyQuery(DIVIDENDS_QUERIES_NOT_AUTH, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!token) {
      allDividendsNotAuth();
    }
  }, [token]);

  return (
    <div className={styles.dividendsWrap}>
      <DocumentTitle data={DOCUMENT_TITLE_SETTINGS.dividends} />
      <div className={styles.pageWrapper}>
        <DividendsHeader />
        <div className={styles.block1}>
          <DividendsPool />
          <DividendsBalance />
        </div>
        <DividendsStarPool />
        <div className={styles.block2}>
          <DividendsTokenDetails />
          <DividendsMiningPrice />
          {token ? (
            <>
              <DividendsStarTokens />
              <DividendsEstimated />
            </>
          ) : null}
        </div>
        <DividendsTable />
      </div>
    </div>
  );
};

export default React.memo(PreRegistrationPage);
