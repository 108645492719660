'use client';
import clsx from 'clsx';

import styles from './styles.module.scss';
import { ITablePlace } from './types';
import { iconByPlace } from './utils';

const TablePlace: React.FC<ITablePlace> = ({ className = '', place }) => {
  const icon = iconByPlace(place);

  return (
    <div className={clsx(styles.place, className)}>
      {icon ? <img className={styles.image} src={icon} alt="place icon" /> : <p className={styles.text}>{place}</p>}
    </div>
  );
};

export default TablePlace;
