import React, { useContext } from 'react';

import { ContextPopUps } from 'context';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';

import CurrencyList from 'components/common/CurrencyList';
import Button from 'components/Base/Button/Button';

import { ICryptoCurrencies } from './types';
import styles from './styles.module.scss';

const CryptoCurrencies: React.FC<ICryptoCurrencies> = ({ data }) => {
  const { translate } = useLocalization();
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const { dividends } = data;

  const handleClose = () => {
    setPopUpsOpen(null);
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{translate('dividends.alocation')}</p>
      <CurrencyList balances={dividends} className={styles.listStyle} />
      <div className={styles.buttonWrap}>
        <Button variant={ButtonType.primary} title={translate('general.buttons.close')} onClick={handleClose} />
      </div>
    </div>
  );
};

export default CryptoCurrencies;
