export const popUps = {
  login: 'login',
  changePasswordSuccess: 'changePasswordSuccess',
  verifySecurityCode: 'verifySecurityCode',
  switch2FA: 'switch2FA',
  securityCodeSuccess: 'securityCodeSuccess',
  forgotPassword: 'forgotPassword',
  passwordSuccess: 'passwordSuccess',
  registration: 'registration',
  signUpConfirm: 'signUpConfirm',
  cancelSignUpConfirm: 'cancelSignUpConfirm',
  deposit: 'deposit',
  withdraw: 'withdraw',
  withdrawSuccess: 'withdrawSuccess',
  collect: 'collect',
  levelUp: 'levelUp',
  avatars: 'avatars',
  progress: 'progress',
  bugBounty: 'bugBounty',
  blockedIp: 'blockedIp',
  provablyFair: 'provablyFair',
  diceBetDetails: 'diceBetDetails',
  minesBetDetails: 'minesBetDetails',
  diceVerify: 'diceVerify',
  minesVerify: 'minesVerify',
  coinflipVerify: 'coinflipVerify',
  blackjackVerify: 'blackjackVerify',
  lottery: 'lottery',
  tipsInfo: 'tipsInfo',
  tipsRequest: 'tipsRequest',
  saveWalletAddressForm: 'saveWalletAddressForm',
  addressBook: 'addressBook',
  walletNavigation: 'walletNavigation',
  supportInfo: 'supportInfo',
  limboBetDetails: 'limboBetDetails',
  wheelFortune: 'wheelFortune',
  gamesLimits: 'gamesLimits',
  hotKeys: 'hotKeys',
  gamesRules: 'gamesRules',
  contacts: 'contacts',
  coinBetDetails: 'coinBetDetails',
  missionsStageGame: 'missionsStageGame',
  missionsStagePrize: 'missionsStagePrize',
  missionsInfo: 'missionsInfo',
  vault: 'vault',
  vaultConfirm: 'vaultConfirm',
  tipsConfirm: 'tipsConfirm',
  kenoBetDetails: 'kenoBetDetails',
  kenoVerify: 'kenoVerify',
  bonusLogin: 'bonusLogin',
  bonusCancel: 'bonusCancel',
  bonusCollect: 'bonusCollect',
  rakebackInfo: 'rakebackInfo',
  rakebackCollect: 'rakebackCollect',
  rakebackCollected: 'rakebackCollected',
  hotKeysBlackJack: 'hotKeysBlackJack',
  blackJackBetDetails: 'blackJackBetDetails',
  buyCrypto: 'buyCrypto',
  landingJoinTerms: 'landingJoinTerms',
  texasHoldemRules: 'texasHoldemRules',
  texasHoldemBetDetails: 'texasHoldemBetDetails',
  texasHoldemVerify: 'texasHoldemVerify',
  plinkoBetDetails: 'plinkoBetDetails',
  plinkoVerify: 'plinkoVerify',
  depositChanged: 'depositChanged',
  totalCollectedInfo: 'totalCollectedInfo',
  dividendsCalculator: 'dividendsCalculator',
  cryptoCurrencies: 'cryptoCurrencies',
  ranks: 'ranks',
  eventResult: 'eventResult',
};

export enum ETokenCode {
  STAR = 'STAR',
  BTC = 'BTC',
  TRX = 'TRX',
  BNB = 'BNB',
  BSC = 'BSC',
  ETH = 'ETH',
  USDT = 'USDT',
  LTC = 'LTC',
  USD = 'USD',
  DOGE = 'DOGE',
  XRP = 'XRP',
}

export const TOKEN_CODES: string[] = Object.values(ETokenCode);

export enum SettingName {
  maintenanceMode = 'maintenance_mode',
  bonusMinDeposit = 'bonus_min_deposit',
  bonusMaxBonus = 'bonus_max_bonus',
  bonusDuration = 'bonus_duration',
  bonusWagerMultiplier = 'bonus_wager_multiplier',
  wheelStatus = 'wheel_status',
  wheelDuration = 'wheel_duration',
  miningDiscount = 'mining_discount',
  bonusRules = 'bonus_rules',
  bonusDate = 'bonus_date',
  bonusWithExistingDeposit = 'bonus_with_existing_deposit',
  starPoolLimit = 'star_pool_limit',
  conversionFee = 'conversion_fee',
  background = 'background',
  logo = 'logo',
  rakebackWithdrawalDelay = 'rakeback_withdrawal_delay',
  rakebackMinWithdrawal = 'rakeback_min_withdrawal',
  texasHoldemLowAnteStake = 'texas_holdem_low_ante_stake',
  texasHoldemLowTripsStake = 'texas_holdem_low_trips_stake',
  texasHoldemLowMaxPayout = 'texas_holdem_low_stake_maximum_payout',
  texasHoldemHighAnteStake = 'texas_holdem_high_ante_stake',
  texasHoldemHighTripsStake = 'texas_holdem_high_trips_stake',
  texasHoldemHighMaxPayout = 'texas_holdem_high_stake_maximum_payout',
  withdrawal = 'withdrawal',
  vipMonthlyReward = 'vip_monthly_reward',
  vipWeeklyReward = 'vip_weekly_reward',
}
export const BonusStatus = {
  active: 'Active',
  inactive: 'Inactive',
  collected: 'Collected',
  expired: 'Expired',
};

const platformUrl = window.location.origin;

export const PLATFORM_URL = platformUrl.match('stage') ? 'https://stage.starbets.io' : 'https://starbets.io';

export const TABLE_CLASSNAMES = {
  column: {
    default: 'default-column',
    flex: 'flex-column',
    hideDesktop: 'hide-desktop',
    hideTablet: 'hide-tablet',
    hide700: 'hide700',
    hideMobile: 'hide-mobile',
  },
  images: {
    currency: 'currency-img',
    avatar: 'avatar-img',
    flag: 'flag-img',
    place: 'place-img',
  },
  text: {
    default: 'default-text',
    statusWin: 'status-win',
    statusLose: 'status-lose',
    userAlias: 'user-alias',
    dateFormat: 'date-format',
    statusComplete: 'status-complete',
    statusFailed: 'status-failed',
    statusHide: 'status-hide',
    cutText: 'cut-text',
    statusCollected: 'status-collected',
    statusExpired: 'status-expired',
    statusCanceled: 'status-canceled',
  },
};

export enum EGamesCategories {
  inHouse = 'in-house',
  allGames = 'all-games',
  slots = 'slots',
  tableGames = 'table-games',
  liveCasino = 'live-casino',
  virtualSport = 'virtual-sport',
}

export enum EGamesTabs {
  favorite = 'favorite',
  new = 'new',
  popular = 'popular',
  bonus = 'bonus',
  dropsAndWins = 'drops-and-wins',
  vip = 'vip',
}

export enum GameCategoryRequest {
  InHouse = 'InHouse',
  LiveCasino = 'LiveCasino',
  TableGames = 'TableGames',
  Slots = 'Slots',
  VirtualSports = 'VirtualSports',
}

export const CATEGORY_NAMES = {
  allGames: { title: 'All Games', urlTitle: EGamesCategories.allGames },
  inHouse: { title: 'In-House', urlTitle: EGamesCategories.inHouse },
  slots: { title: 'Slots', urlTitle: EGamesCategories.slots },
  tableGames: { title: 'Table Games', urlTitle: EGamesCategories.tableGames },
  liveCasino: { title: 'Live Casino', urlTitle: EGamesCategories.liveCasino },
};

export const GAMES_TABS_NAMES = {
  favorite: { title: 'games.tabs.favorite', urlTitle: EGamesTabs.favorite },
  new: { title: 'games.tabs.new', urlTitle: EGamesTabs.new },
  popular: { title: 'games.tabs.popular', urlTitle: EGamesTabs.popular },
  bonus: { title: 'games.tabs.bonus', urlTitle: EGamesTabs.bonus },
  dropsAndWins: { title: 'games.tabs.drops', urlTitle: EGamesTabs.dropsAndWins },
  vip: { title: 'games.tabs.vip', urlTitle: EGamesTabs.vip },
};

export const GAMES_QUALITY = {
  categories: {
    id: 'category',
    titles: {
      allGamesTitle: 'games.categories.all',
      allGamesURL: EGamesCategories.allGames,
      inHouseTitle: 'games.categories.in-house',
      inHouseURL: EGamesCategories.inHouse,
      favoriteTitle: 'games.categories.favorite',
      favoriteURL: EGamesTabs.favorite,
    },
  },
  providers: {
    id: 'provider',
    titles: {
      allProvidersTitle: 'games.providers.all',
      allProvidersURL: 'all-providers',
    },
  },
  tabs: {
    id: 'tab',
  },
};

export const EVENT_STATE = {
  started: 'events.state.started',
  notStarted: 'events.state.not-started',
  finished: 'events.state.finished',
};

export enum ReferralsType {
  affiliate = 'aff',
  referral = 'ref',
}

export enum UserType {
  user = 'USER',
  affiliate = 'AFFILIATE',
}

export const MINES_CONDITION = {
  notStarted: 'notStarted',
  getStarted: 'getStarted',
  selection: 'selection',
  finished: 'finished',
  autoNotStarted: 'autoNotStarted',
  autoStarted: 'autoStarted',
};

export const SQUARE_CLASSES = {
  default: 'square__default',
  win: 'square__win',
  active: 'square__active',
  lose: 'square__lose',
  loseSingle: 'square__single',
  finished: 'square__finished',
  autoSelection: 'auto__selection',
};

export const AUTO_BETTING_STATE = {
  betsNumbers: 'betsNumbers',
  maxBetAmount: 'maxBetAmount',
  onLossIncrease: 'onLossIncrease',
  isOnLossIncrease: 'isOnLossIncrease',
  onWinIncrease: 'onWinIncrease',
  isOnWinIncrease: 'isOnWinIncrease',
  stopOnLossAmount: 'stopOnLossAmount',
  stopOnLossAmountDisplay: 'stopOnLossAmountDisplay',
  stopOnWinAmount: 'stopOnWinAmount',
  stopOnWinAmountDisplay: 'stopOnWinAmountDisplay',
  firstSpin: 'firstSpin',
  autoBetAmount: 'autoBetAmount',
};

export const DEFAULT_META_DESCRIPTION =
  'StarBets.io is the one-stop shop for all your online crypto casino needs. Gamble with crypto and play the best games';

export const DOCUMENT_TITLE_SETTINGS = {
  home: {
    title: 'Best Online Crypto Casino - Gamble with Crypto!',
    description:
      'StarBets.io is the one-stop shop for all your online crypto casino needs. Gamble with crypto and play the best games',
  },
  games: {
    title: 'StarBets - Play over 1000+ Games',
    description: 'Play over 1000 games including Dice, Mines, Slots, BlackJack, Roulette and many more...',
  },
  dividends: {
    title: 'StarBets - Earn Daily Dividends',
    description: 'Earn Passive Income by playing at StarBets and getting daily Dividend Distributions',
  },
  cashback: {
    title: 'StarBets - Up to 30% Cashback',
    description: 'Move up the Ranks and Increase your Cashback up to 30%, the highest in the industry.',
  },
  referral: {
    title: 'StarBets - Referral Program',
    description: 'Refer your friends and followers and get generous rewards.',
  },
  events: {
    title: 'StarBets - Special Events with Big Prizes',
    description: 'Participate in our Special Events to win Big Prizes in many cryptocurrencies',
  },
  whitePaper: {
    title: 'StarBets Whitepaper - Learn about us',
    description: 'Learn about Starbets - who we are, how to maximize your rewards and fun. Play and win',
  },
  dice: {
    title: 'StarBets Dice - In-House Original Game',
    description:
      'Play Dice, our in-house developed game. Use different auto strategies to Win BIG and Mine STAR Tokens',
  },
  diceSeo: {
    title: 'Roll the Dice and Win Big at StarBets: Your Premier Crypto Casino',
    description:
      'Discover the electrifying world of dice games at StarBets, the leading online crypto casino. Roll the dice, win big, and enjoy provably fair gaming, instant transactions, and a thrilling variety of options.',
  },
  mines: {
    title: 'StarBets Mines - In-House Original Game',
    description:
      'Play Mines, our in-house developed game. Find Diamonds and avoid Mines to Win BIG and Mine STAR Tokens',
  },
  limbo: {
    title: 'StarBets Limbo - In-House Original Game',
    description:
      'Play Limbo, our in-house developed game. Use different auto strategies to Win BIG and Mine STAR Tokens',
  },
  coin: {
    title: 'StarBets Coin Flip - In-House Original Game',
    description:
      'Play Coin Flip, our in-house developed game. Use different auto strategies to Win BIG and Mine STAR Tokens',
  },
  keno: {
    title: 'Keno Game at StarBets - Play Online Keno with Bitcoins',
    description:
      'Discover the excitement of playing online keno with Bitcoins. Fast, convenient, and secure - play and win with crypto today!',
  },
  missions: {
    title: 'StarBets Missions - Complete Missions and get a guaranteed prize',
    description: 'Complete Missions tasks for each stage and multiply your winnings.',
  },
  slots: {
    title: '[game name] from [provider] at StarBets',
    description: 'Play [game name] from [provider]. [category] at StarBets',
  },
  dashboard: {
    title: 'StarBets - Best Online Crypto Casino | Bitcoin, Ethereum, TRON, BNB',
    description:
      'StarBets is the best online crypto casino with optimal rewards - Daily Dividends, Cashback, Special Events and other bonuses. Fast Deposits and Withdrawals in many cryptocurrencies.',
  },
  affiliates: {
    title: 'StarBets - Best Online Crypto Casino | Bitcoin, Ethereum, TRON, BNB',
    description:
      'StarBets is the best online crypto casino with optimal rewards - Daily Dividends, Cashback, Special Events and other bonuses. Fast Deposits and Withdrawals in many cryptocurrencies.',
  },
  terms: {
    title: 'StarBets - Best Online Crypto Casino | Bitcoin, Ethereum, TRON, BNB',
    description:
      'StarBets is the best online crypto casino with optimal rewards - Daily Dividends, Cashback, Special Events and other bonuses. Fast Deposits and Withdrawals in many cryptocurrencies.',
  },
  security: {
    title: 'StarBets - Best Online Crypto Casino | Bitcoin, Ethereum, TRON, BNB',
    description:
      'StarBets is the best online crypto casino with optimal rewards - Daily Dividends, Cashback, Special Events and other bonuses. Fast Deposits and Withdrawals in many cryptocurrencies.',
  },
  transaction: {
    title: 'StarBets - Best Online Crypto Casino | Bitcoin, Ethereum, TRON, BNB',
    description:
      'StarBets is the best online crypto casino with optimal rewards - Daily Dividends, Cashback, Special Events and other bonuses. Fast Deposits and Withdrawals in many cryptocurrencies.',
  },
  account: {
    title: 'StarBets - Best Online Crypto Casino | Bitcoin, Ethereum, TRON, BNB',
    description:
      'StarBets is the best online crypto casino with optimal rewards - Daily Dividends, Cashback, Special Events and other bonuses. Fast Deposits and Withdrawals in many cryptocurrencies.',
  },
  roulettePage: {
    title: 'Roulette Casino at StarBets – Play with Bitcoins',
    description:
      'Guide to playing online roulette with bitcoins at crypto casinos | Tips, Strategies, and Why StarBets.io is the Best Crypto Casino!',
  },
  livePage: {
    title: 'Live Dealer Casino Games with Crypto @ StarBets',
    description:
      '🌟🔒 Experience Live Casino Games with Crypto at StarBets 🎰💰 Embrace Secure Transactions and Unforgettable Gaming Thrills!',
  },
  slotsSeoPage: {
    title: 'Crypto Slots - Play Online Slots with Bitcoin',
    description:
      'Discover the world of crypto slots and play your favourite online slots with crypto. Explore a wide variety of Bitcoin slots for every taste!',
  },
  blog: {
    title: 'Casino Crypto Blog @ StarBets - News, Guides & Tips',
    description:
      '🌟📰 Your Ultimate Casino Crypto Blog @ StarBets: Stay in the Loop with News, Guides & Tips! 💡🎲 Get Ahead in the Crypto Gaming World!',
  },
  faq: {
    title: 'StarBets - FAQ',
    description:
      'StarBets is the best online crypto casino with optimal rewards - Daily Dividends, Cashback, Special Events and other bonuses. Fast Deposits and Withdrawals in many cryptocurrencies.',
  },
  bonus: {
    title: 'Crypto Casino Bonuses at StarBets.io - Claim the Best Offers',
    description:
      ' Ready to Win Big? Get Your Hands on Stellar Crypto Casino Bonuses at StarBets.io! Blast off to Riches Today! ',
  },
  blackJack: {
    title: 'Play Blackjack at The Best Crypto Casino and Win Big | Best Crypto Casino',
    description:
      'Experience the thrill of our in-house blackjack game at the best crypto casino. Play now and test your skills to win big with cryptocurrency. Join us today!',
  },
  blackJackSeoPage: {
    title: 'Crypto Blackjack at StarBets.io - Play with Bitcoins',
    description:
      '♠️🌟 Hit or Stand? Play Crypto Blackjack at StarBets.io with Bitcoins 💰🃏 Unleash Your Skills and Win Big in the Digital Casino!',
  },
  bitcoinPage: {
    title: 'Bitcoin Casino Gambling at StarBets - the Best Bitcoin Site',
    description:
      ' 🎰🚀 Go Beyond Limits: Experience Bitcoin Casino Gambling at StarBets 🌟💰 Unleash the Future of Gaming with Bitcoins!',
  },
  tetherPage: {
    title: 'Tether (USDT) Casino Gambling at StarBets',
    description:
      '🌟💰 Play with Stability: Tether (USDT) Casino Gambling at StarBets 🃏🚀 Explore Secure Crypto Gaming with Icons of Trust!',
  },
  ethereumPage: {
    title: 'Ethereum Online Casino at StarBets - Gamble with ETH',
    description:
      "🌟💰 Elevate Your Game with Ethereum: StarBets' ETH Online Casino 🃏🌐 Explore Crypto Gaming at Its Finest! 🔥",
  },
  ukSeoPage: {
    title: "UK's Best Crypto Online Casino at StarBets - Play with Bitcoins",
    description:
      "UK's Crypto Casino Paradise at StarBets UK: Play with Bitcoins for Limitless Thrills! 🇬🇧🃏 Experience Digital Gaming Excellence! 🔥🚀",
  },
  plinko: {
    title: 'Plinko Game at StarBets - Play Online Keno with Bitcoins',
    description:
      'Discover the excitement of playing online keno with Bitcoins. Fast, convenient, and secure - play and win with crypto today!',
  },
};

export enum OutLinks {
  promo = 'https://www.dropbox.com/sh/qs3h4q55mytai3t/AACP8pGdRAPTl5y-vtsAPhz2a?dl=0',
  responsibleGambling = 'https://www.begambleaware.org/',
  affiliateApp = 'https://affiliates.starbets.io/',
}

export const FIREBLOCKS_RELEASE_DATE = '2024-10-24T00:00:00.000Z';
export const FIREBLOCKS_NOTIFICATION_COOKIE = 'starbets-deposit-notification';
