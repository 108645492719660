import { ITotalCollected } from 'components/Pages/BoonusPage/components/BonusTotalCollected/types';

export interface ITotalCollectedInfo {
  data: {
    cashback: ITotalCollected[];
    rakeback: ITotalCollected[];
    dividends: ITotalCollected[];
    referral: ITotalCollected[];
    total: string;
  };
}

export enum ETotalCollected {
  dividends = 'dividends',
  referral = 'referral',
  cashback = 'cashback',
  rakeback = 'rakeback',
}
