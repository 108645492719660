import { animateScroll as scroll } from 'react-scroll';

import logOutIcon from 'assets/img/common/logOutIcon.webp';

import { Links } from 'components/constants';
import { useLocalization } from 'components/Internationalization';
import { useSession } from 'context/contextSessionManagement/context';

import TooltipCustom from 'components/Base/Tooltip';
import NavLink from 'components/Base/Links/NavLink';

import { IUserTooltip } from './types';
import styles from './styles.module.scss';

const PopoverItem: React.FC<IUserTooltip> = ({ link, title, handleInfo }) => {
  return (
    <div className={styles.userInfoContentItem} onClick={handleInfo}>
      <div className={styles.userInfoContentElem}>
        <>
          <i className={styles.right} />
          <NavLink caseSensitive to={link} onClick={() => scroll.scrollTo(0, { duration: 0, delay: 0 })}>
            <span>{title}</span>
          </NavLink>
        </>
      </div>
    </div>
  );
};

interface PropsUser {
  state: boolean;
  setState: (elem: boolean) => void;
  handleInfo?: () => void;
}

const UserTooltip: React.FC<PropsUser> = ({ state, setState, handleInfo }) => {
  const { translate } = useLocalization();
  const { logout } = useSession();

  const handleLogOut = () => {
    setState(!state);
    logout();
  };

  return (
    <TooltipCustom top="65px" right="-20px" state={state} width="200px" height="" arrow="80px">
      <div className={styles.userInfo}>
        <div className={styles.userInfoContent}>
          {/* <PopoverItem title={translate('nav.dashboard')} link={Links.dashboard} handleInfo={handleInfo} /> */}
          <PopoverItem title={translate('nav.account')} link={Links.account} handleInfo={handleInfo} />
          <PopoverItem title={translate('nav.security')} link={Links.security} handleInfo={handleInfo} />
          <PopoverItem title={translate('nav.transactions')} link={Links.transactions} handleInfo={handleInfo} />
          <PopoverItem title={translate('nav.bets')} link={Links.bets} handleInfo={handleInfo} />
        </div>
        <div className={styles.userInfoFooter}>
          <img src={logOutIcon} alt="icon" onClick={handleLogOut} />
          <p onClick={handleLogOut}>{translate('nav.logout')}</p>
        </div>
      </div>
    </TooltipCustom>
  );
};

export default UserTooltip;
