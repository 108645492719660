import { gql } from '@apollo/client';

export const GET_MY_BONUSES = gql`
  query myBonuses {
    myBonuses {
      amount
      startAt
      endAt
      tokenCode
      displayName
      currentWager
      requiredWager
      status
      selected
      id
      userFreespin {
        numberOfSpins
      }
      bonus {
        name
        rules
        maxBonus
        minDeposit
        wagerMultiplier
        minBet
        maxBet
        type
        freeSpinSetting {
          gameId
          numberOfSpins
          currency
          game {
            name
            image
            slug
          }
        }
      }
    }
  }
`;

export const GET_BONUSES = gql`
  query bonuses {
    bonuses {
      id
      name
      rules
      maxBonus
      minDeposit
      minBet
      maxBet
      status
      depositDuration
      type
      freeSpinSetting {
        gameId
        game {
          name
          image
          slug
        }
      }
    }
  }
`;
