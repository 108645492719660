import { useQuery } from '@apollo/client';

import { useAppSelector } from 'hooks/useAppSelector';
import { DOCUMENT_TITLE_SETTINGS } from 'components/constants/constants';
import { userToken } from 'store/user/user.selectors';
import { CASHBACK_QUERIES_AUTH } from 'graphQl/query/cashback/cashbackQueriesAuth';

import DocumentTitle from 'components/Base/DocumentTitle';
import Title from './components/Title';
import CashbackBalance from './components/CashbackBalance';
import CashbackSummary from './components/CashbackSummary';
import CashbackTable from './components/CashbackTable';

import styles from './styles.module.scss';

const CashbackPage: React.FC = () => {
  const token = useAppSelector(userToken);

  useQuery(CASHBACK_QUERIES_AUTH, { fetchPolicy: 'cache-and-network', skip: !token });

  return (
    <div className={styles.wrapper}>
      <DocumentTitle data={DOCUMENT_TITLE_SETTINGS.cashback} />
      <div className={styles.page}>
        <Title />
        <div className={styles.main}>
          <CashbackSummary />
          <CashbackBalance />
        </div>
        {token ? <CashbackTable /> : null}
      </div>
    </div>
  );
};

export default CashbackPage;
