import clsx from 'clsx';

import { getImgSrc } from 'func/common';

import styles from './styles.module.scss';
import { ICurrencyList } from './types';

const CurrencyList: React.FC<ICurrencyList> = ({ balances, className = '' }) => {
  return (
    <div className={clsx(styles.wrapper, className)}>
      {balances.map((b) => (
        <div key={b.src} className={styles.row}>
          <p>{b.displayName}</p>
          <div className={styles.rowCurrency}>
            <img src={getImgSrc(b.src)} alt={b.src} />
            <h5>{b.title}</h5>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CurrencyList;
