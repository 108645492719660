import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useEffect, useState } from 'react';
import { useTimer } from 'react-timer-hook';

import styles from './styles.module.scss';
import { IDividendsTimer } from './types';

dayjs.extend(utc);

const DividendsTimer: React.FC<IDividendsTimer> = ({ onTick }) => {
  const [distributionTime, setDistributionTime] = useState<Date | null>(null);

  const { hours, minutes, seconds, restart } = useTimer({
    expiryTimestamp: new Date(),
    onExpire: () => {
      if (distributionTime) {
        onTick();
        setDistributionTime((t) => dayjs(t).add(1, 'day').toDate());
      }
    },
  });

  useEffect(() => {
    const now = dayjs();
    const newDistributionTime = dayjs.utc().set('hour', 13).set('minute', 0).set('second', 0);

    if (now.isBefore(newDistributionTime)) {
      setDistributionTime(newDistributionTime.toDate());
      return;
    }

    const updatedTime = newDistributionTime.add(1, 'day');

    setDistributionTime(updatedTime.toDate());
  }, []);

  useEffect(() => {
    if (distributionTime) {
      restart(distributionTime, true);
    }
  }, [distributionTime]);

  return (
    <div className={styles.dividendsTimer}>
      {`0${hours}`.slice(-2)}:{`0${minutes}`.slice(-2)}:{`0${seconds}`.slice(-2)}
    </div>
  );
};

export default DividendsTimer;
