import styled from 'styled-components';

interface Props {
  linecolor: string;
  heightmob?: string;
  long?: boolean;
  largeimage?: string;
  middleimage?: boolean;
  bigimage?: boolean | undefined;
  titlesize?: 'small' | 'big' | 'large';
  title?: string;
}

const TitleStyle = styled.div.withConfig({
  shouldForwardProp: (prop) => !['linecolor'].includes(prop),
})<Props>`
  max-width: 1339px;
  width: 100%;
  display: flex;
  height: auto;
  align-items: center;
  margin: 24px 0;

  .title__icon {
    display: block;
    width: ${(props) => (props.bigimage ? '120px' : '150px')};
    height: ${(props) => (props.bigimage ? '120px' : '150px')};
    margin-left: ${(props) => (props.bigimage ? '-35px' : '-50px')};
    margin-left: ${(props) => props.middleimage && '-35px'};
    margin: ${(props) => props.largeimage && '0 50px 0 0'};
    width: ${(props) => props.largeimage && '50px'};
    height: ${(props) => props.largeimage && '50px'};
    height: ${(props) => props.middleimage && '120px'};
    width: ${(props) => props.middleimage && '120px'};
    margin-right: 20px !important;
  }
  .title__body {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin: ${(props) => (props.bigimage ? '0 0 0 15px ' : '0')};
    margin: ${(props) => props.middleimage && '0 0 0 15px'};
  }

  .title__body-line {
    position: absolute;
    top: 25px;
    width: 104px;
    height: 3px;
    border-radius: 1.5px;
    background-color: ${(props) => props.linecolor};
  }
  .title__body-title {
    text-transform: uppercase;
    font-family: 'Montserrat';
    font-weight: 800;
    font-size: 48px;
    color: #ffffff;
    margin: ${(props) => (props.bigimage ? '25px 0 0 0 ' : '0')};
    margin-top: ${(props) => props.long && '15px'};
  }
  .title__description {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
  }

  @media (max-width: 1300px) {
  }
  @media (max-width: 1150px) {
    .title__body-title {
      font-size: ${(props) => (props.long || props.titlesize === 'large') && '40px'};
    }
  }
  @media (max-width: 1024px) {
    .title__body-title {
      font-size: ${(props) => (props.long || props.titlesize === 'large') && '28px'};
      margin: ${(props) => (props.bigimage ? '15px 0 0 0 ' : '0')};
    }
  }
  @media (max-width: 768px) {
    .title__body-title {
      font-size: ${(props) => (props.long || props.titlesize === 'large') && '26px'};
    }
    .title__description {
      font-size: 13px;
    }
  }
  @media (max-width: 640px) {
    justify-content: center;
    // .title__body {
    //   margin: 0;
    // }
    .title__body-line {
      left: 50%;
      transform: translateX(-50%);
    }
    .title__icon {
      display: none;
    }
  }
  @media (max-width: 580px) {
    height: ${(props) => props.long && '180px'};
    .title__body-title {
      font-size: ${(props) => (props.long || props.titlesize === 'large') && '34px'};
    }
    .title__body {
      height: ${(props) => props.long && '180px'};
    }
  }
  @media (max-width: 500px) {
    .title__body-title {
      font-size: 35px;
      font-size: ${(props) => props.titlesize === 'small' && '48px'};
      font-size: ${(props) => props.titlesize === 'big' && '40px'};
      font-size: ${(props) => props.titlesize === 'large' && '32px'};
    }
  }
  @media (max-width: 420px) {
    // height: ${(props) => (props.long ? '132px' : '112px')};
    // margin-bottom: -20px;
    .title__body-title {
      font-size: 30px;
      font-size: ${(props) => props.long && '28px'};
      font-size: ${(props) => props.titlesize === 'large' && '24px'};
    }
    height: ${(props) => props.heightmob || ''};
  }
  @media (max-width: 365px) {
    .title__body-title {
      font-size: 30px;
      font-size: ${(props) => props.long && '24px'};
      font-size: ${(props) => props.titlesize === 'large' && '24px'};
    }
  }
  @-moz-document url-prefix('') {
    @media (max-width: 580px) {
      .title__body {
        height: ${(props) => props.long && '180px'};
      }
    }
  }
`;

export default TitleStyle;
