import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import clsx from 'clsx';
import Big from 'big.js';
import { pathOr } from 'ramda';

import referralIcon from 'assets/img/ReferralPage/card-banner.webp';
import copyIcon from 'assets/img/common/copyIcon.svg';
import noiseIcon from 'assets/img/ReferralPage/noise.svg';

import { useAppSelector } from 'hooks/useAppSelector';
import { userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';
import { amount, formatUsdAmount, getImgSrc } from 'func/common';
import { ETokenCode, PLATFORM_URL } from 'components/constants/constants';
import { ButtonType } from 'components/Base/Button/types';
import { useNavigate } from 'hooks/useNavigate';
import { GET_REFERRAL_ACTIVITY } from 'graphQl/query/referral/referralActivity';
import { GET_REFERRAL_CODE } from 'graphQl/query/referral/referralCode';
import { Links } from 'components/constants';
import { GET_MY_REFERRALS_BALANCE } from 'graphQl/query/referral/myReferrals';
import { EXCHANGE_RATES } from 'graphQl/query/exchangeRate/exchangeRate';
import { IExchangeRate } from 'types';
import { totalAvailableCurrencies, totalCollectedCurrencies, totalCollectedUsd } from 'func/prepareDataBonus';
import { getTotalEarnedStars } from 'func/prepareDataReferral';

import Button from 'components/Base/Button';
import DividendsInfo from 'components/Pages/DividendsPage/components/DividendsInfo';

import styles from './styles.module.scss';
import NotAuthCover from 'components/common/NotAuthCover';

const referralRef = '/?ref=';

const ReferralLink: React.FC = () => {
  const token = useAppSelector(userToken);
  const navigate = useNavigate();
  const { translate } = useLocalization();

  const [referralCode, setReferralCode] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [referrals, setReferrals] = useState(0);
  const [exchangeRates, setExchangeRates] = useState<IExchangeRate[]>([]);
  const [totalEarned, setTotalEarned] = useState('0');
  const [totalStars, setTotalsStars] = useState('0');

  const { data: referralBalanceResponse } = useQuery(GET_MY_REFERRALS_BALANCE, {
    fetchPolicy: 'cache-and-network',
    skip: !token,
  });
  const { data: exchangeRatesData } = useQuery(EXCHANGE_RATES, { fetchPolicy: 'cache-and-network' });

  useQuery(GET_REFERRAL_ACTIVITY, {
    variables: {
      input: {
        limit: 5,
      },
    },
    onCompleted: (dataActivity) => {
      setReferrals(dataActivity.referralActivity.count);
    },
    skip: !token,
  });

  const { data: daraCode } = useQuery(GET_REFERRAL_CODE, {
    fetchPolicy: 'cache-only',
  });

  const referralCodDkt = PLATFORM_URL + referralRef + referralCode;

  const handleCopy = () => {
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 1000);
  };

  const handlePromo = () => navigate(Links.promoMaterials);

  useEffect(() => {
    if (daraCode) {
      setReferralCode(daraCode.profile.referralCode);
    }
  }, [daraCode]);

  useEffect(() => {
    if (exchangeRatesData) {
      const newExchangeRates = pathOr<IExchangeRate[]>([], ['exchangeRates'], exchangeRatesData);

      setExchangeRates(newExchangeRates);
    }
  }, [exchangeRatesData]);

  useEffect(() => {
    if (referralBalanceResponse) {
      const { myReferralBalances } = referralBalanceResponse;
      const totalCurrencies = totalAvailableCurrencies(myReferralBalances, exchangeRates);
      const totalCollectedCurrency = totalCollectedCurrencies(myReferralBalances, exchangeRates);
      const totalBalance = totalCollectedUsd(totalCurrencies);
      const totalCollect = totalCollectedUsd(totalCollectedCurrency);
      const totalUsd = Big(totalBalance).plus(totalCollect).valueOf();
      const starsAmount = getTotalEarnedStars(myReferralBalances);

      setTotalEarned(totalUsd);
      setTotalsStars(starsAmount);
    }
  }, [referralBalanceResponse, exchangeRates]);

  return (
    <div className={clsx(styles.wrapper, token ? styles.padding : '')}>
      <img className={token ? styles.icon : styles.hidden} src={referralIcon} alt="pool" />
      <div className={clsx(styles.header, !token ? styles.padding : '')}>
        <h3>{translate('referral.link.title')}</h3>
        <DividendsInfo text={translate('referral.link.description')} />
      </div>
      <div className={clsx(styles.body, !token ? styles.padding : '')}>
        <div className={styles.earn}>
          <h2>{translate('referral.link.earn')}</h2>
          <div className={styles.earnLineWrap}>
            <div className={styles.earnLine}>
              <h6>5%</h6>
              <p>{translate('referral.link.mined')}</p>
            </div>
            <div className={styles.earnLine}>
              <h6>0.1%</h6>
              <p>{translate('referral.link.every')}</p>
              <h4>{translate('referral.link.won')}</h4>
            </div>
          </div>
        </div>
      </div>
      {token ? (
        <>
          <h3 className={styles.linkTitle}>{translate('referral.link.title')}:</h3>
          <div className={styles.linkWrap}>
            <h5>https://starbets.io{referralRef + referralCode}</h5>
            <CopyToClipboard text={referralCodDkt}>
              <button className={styles.linkBodyButton} onClick={handleCopy}>
                <p>{translate('referral.link.button.copy.text')}</p>
                <img src={copyIcon} alt="icon" />
                <div className={clsx(styles.linkBodyTooltip, showTooltip ? styles.open : '')}>
                  {translate('referral.link.tooltip')}
                </div>
              </button>
            </CopyToClipboard>
          </div>
          <div className={styles.share}>
            <img src={noiseIcon} alt="share" />
            <p>{translate('referral.link.share')}</p>
          </div>
          <div className={styles.stats}>
            <div className={styles.statsItem}>
              <p>{translate('referral.link.card.text')}:</p>
              <div>
                <img src={getImgSrc(ETokenCode.STAR)} alt={ETokenCode.STAR} className={styles.statsItemReferral} />
                <h2>{referrals}</h2>
              </div>
            </div>
            <div className={styles.statsItem}>
              <p>{translate('referral.link.total.usd')}</p>
              <div>
                <img src={getImgSrc(ETokenCode.USD)} alt={ETokenCode.USD} />
                <h2>{formatUsdAmount(totalEarned)}</h2>
              </div>
            </div>
            <div className={styles.statsItem}>
              <p>{translate('referral.link.total.star')}</p>
              <div>
                <img src={getImgSrc(ETokenCode.STAR)} alt={ETokenCode.STAR} />
                <h2>{amount(totalStars)}</h2>
              </div>
            </div>
          </div>
          <div className={styles.buttonWrap}>
            <Button
              variant={ButtonType.secondary}
              width="100%"
              title={translate('referral.link.buttton.promo.text')}
              onclick={handlePromo}
            />
          </div>
        </>
      ) : (
        <div className={styles.notAuthWrap}>
          <NotAuthCover className={styles.noShadow} />
        </div>
      )}
    </div>
  );
};

export default ReferralLink;
