import { createSlice } from '@reduxjs/toolkit';

import { generalDataInitialState } from './generalData.state';
import generalDataReducer from './generalData.reducer';

const generalDataSlice = createSlice({
  name: 'generalData',
  initialState: generalDataInitialState,
  reducers: generalDataReducer,
});

export const {
  setVipRank,
  setRanksList,
  setEventsList,
  setExchangeRates,
  setLotteryState,
  setSettings,
  setActiveTokens,
} = generalDataSlice.actions;
export default generalDataSlice.reducer;
