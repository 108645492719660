import styled from 'styled-components';

import point from 'assets/img/common/cursor-pointer.webp';

const RankSystemSliderStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .rank__slider__wrapper {
    display: flex !important;
    flex-direction: column;
  }

  .rank__slider__image--wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 220px;
  }

  .rank__slider__image {
    width: 125px;
    height: auto;
  }

  .rank__title {
    padding: 4px 8px;

    font-style: normal;
    font-weight: 600;
    font-size: 15.4719px;
    line-height: 19px;
    color: #fccd00;

    background: linear-gradient(180deg, rgba(214, 145, 21, 0.4) 0%, rgba(214, 145, 21, 0.16) 100%), #424862;
    border-radius: 4px;
  }

  .rank__slider__info {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 4px;
  }

  .rank__vip {
    padding: 4px 8px;

    font-style: normal;
    font-weight: 600;
    font-size: 15.4719px;
    line-height: 19px;
    color: #795620;

    background: linear-gradient(90deg, #ffffb2 0%, #ebbb2e 100%), #424862;
    border-radius: 4px;
  }

  .slick-slider {
    width: 200px;
    height: max-content;
  }

  .slick-next,
  .slick-prev {
    font-size: 0;
    line-height: 0;
    width: 40px !important;
    height: 40px !important;
    cursor: url(${point}), auto !important;
    color: #ffffff !important;
    border: none;
    outline: 0;
    background: rgba(255, 255, 255, 0.24) !important;
    border-radius: 8px !important;
  }
  .slick-next {
    top: 50% !important;
    right: -25px !important;

    transform: translateY(-50%);
    background: transparent !important;
  }
  .slick-prev {
    z-index: 2 !important;
    top: 50% !important;
    left: -25px !important;

    background: transparent !important;
  }
  .slick-next:before,
  .slick-prev:before {
    font-size: 20px;
    line-height: 1;
    opacity: 1;
    color: red !important;
  }
  .slick-next:before {
    content: '' !important;
    border: 1px solid #ffffff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 5px;
    height: 0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin-right: 5px;
  }
  .slick-prev:before {
    content: '' !important;
    border: 1px solid #ffffff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 5px;
    height: 0;
    transform: rotate(-225deg);
    -webkit-transform: rotate(-225deg);
    margin-left: 5px;
  }
`;

export default RankSystemSliderStyles;
