import { useQuery } from '@apollo/client';

import { useAppSelector } from 'hooks/useAppSelector';
import { userProfile, userToken } from 'store/user/user.selectors';
import { REFERRAL_QUERIES_AUTH } from 'graphQl/query/referral/referralQueriesAuth';
import { DOCUMENT_TITLE_SETTINGS, UserType } from 'components/constants/constants';
import { Links } from 'components/constants';
import { useNavigate } from 'hooks/useNavigate';

import DocumentTitle from 'components/Base/DocumentTitle';
import ReferralTable from './components/ReferralTable';
import ReferralHeader from './components/ReferralHeader';
import ReferralBalanceNew from './components/ReferralBalanceNew';
import ReferralLink from './components/ReferralLink';

import styles from './styles.module.scss';

const ReferralPage: React.FC = () => {
  const navigate = useNavigate();

  const token = useAppSelector(userToken);
  const profile = useAppSelector(userProfile);

  useQuery(REFERRAL_QUERIES_AUTH, {
    fetchPolicy: 'cache-and-network',
    skip: !token,
  });

  if (profile?.type === UserType.affiliate) {
    navigate(Links.notFound);
    return;
  }

  return (
    <div className={styles.wrapper}>
      <DocumentTitle data={DOCUMENT_TITLE_SETTINGS.referral} />
      <ReferralHeader />
      <div className={styles.pageWrapper}>
        <div className={styles.block1}>
          <ReferralLink />
          <ReferralBalanceNew />
        </div>
        {token ? <ReferralTable /> : null}
      </div>
    </div>
  );
};

export default ReferralPage;
