/* eslint-disable no-useless-escape */
import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import dividendsIcon from 'assets/img/DividendsPage/estimated.webp';

import { ContextPopUps, ContextTokenCode } from 'context';
import { useAppSelector } from 'hooks/useAppSelector';
import { userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';
import { GET_DIVIDENDS_POOL } from 'graphQl/query/dividends/dividendsPool';
import { GET_STAR_TOKENS } from 'graphQl/query/dividends/starTokens';
import { BNDivide, getImgSrc } from 'func/common';
import { getEstimateDropdownType, handlePeriodCalculate } from 'func/prepareDataDividends';
import { EDividendsDropdown } from 'components/constants/dividends';
import { ICurrencyItem } from 'components/Base/CurrensiesTemplate/types';
import { ButtonType } from 'components/Base/Button/types';
import { getDefaultCurrenciesTemplate } from 'func/prepareDataAffiliates';
import { ETokenCode } from 'components/constants/constants';

import InputCustom from 'components/Base/Input';
import Button from 'components/Base/Button/Button';
import DropDown from 'components/Base/DropDown';
import CurrencyList from 'components/common/CurrencyList';

import styles from './styles.module.scss';

const DividendsCalculator: React.FC = () => {
  const { setPopUpsOpen } = useContext(ContextPopUps);
  const token = useAppSelector(userToken);
  const { language, translate } = useLocalization();
  const { tokenWithNetworksList } = useContext(ContextTokenCode);

  const [starAmount, setStarAmount] = useState('');
  const [dropdownInfo, setDropdownInfo] = useState([]);
  const [type, setType] = useState(null);
  const [estimateData, setEstimateData] = useState(null);
  const [balance, setBalance] = useState([]);
  const [tokenData, setTokenData] = useState<ICurrencyItem[]>([]);
  const [totalStaked, setTotalStaked] = useState('0');
  const [dropTitles, setDropTitles] = useState([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [usdData, setUsdData] = useState(null);

  useQuery(GET_DIVIDENDS_POOL, {
    onCompleted: (data) => {
      setEstimateData(data?.dividendPool);
    },
  });

  const { data: dataStarTokens } = useQuery(GET_STAR_TOKENS, {
    skip: !token,
    fetchPolicy: 'cache-only',
  });

  const handleDaily = () => {
    const percent = Number(BNDivide(starAmount, Number(totalStaked))) * 100;
    handlePeriodCalculate(Number(percent), 1, estimateData, setBalance, setUsdData);
  };

  const handleMonthly = () => {
    const percent = Number(BNDivide(starAmount, Number(totalStaked))) * 100;
    handlePeriodCalculate(Number(percent), 30, estimateData, setBalance, setUsdData);
  };

  const handleQuarterly = () => {
    const percent = Number(BNDivide(starAmount, Number(totalStaked))) * 100;
    handlePeriodCalculate(Number(percent), 90, estimateData, setBalance, setUsdData);
  };

  const handleCalc = () => {
    switch (type) {
      case EDividendsDropdown.daily:
        return handleDaily();
      case EDividendsDropdown.monthly:
        return handleMonthly();
      case EDividendsDropdown.quarterly:
        return handleQuarterly();
      default:
        return handleDaily();
    }
  };

  const handleChangePercent = (e) => {
    let value = '';
    value = e.target.value;
    const valid = /^\-?\d+\.\d*$|^\-?[\d]*$/;
    const number = /\-\d+\.\d*|\-[\d]*|[\d]+\.[\d]*|[\d]+/;
    if (e.nativeEvent.inputType === 'deleteContentBackward') {
      setStarAmount(e.target.value);
      return;
    }
    if (value === '0') {
      setStarAmount('1');
      return;
    }
    if (!valid.test(value)) {
      const n = value.match(number);
      value = n ? n[0] : '';
    }
    setStarAmount(value);
  };

  const handleClose = () => {
    setPopUpsOpen(null);
  };

  useEffect(() => {
    if (dataStarTokens) {
      setTotalStaked(dataStarTokens.starToken.totalStaked);
    }
  }, [dataStarTokens]);

  useEffect(() => {
    if (dropTitles.length) {
      const dropDownData = dropTitles.map((elem, index) => ({
        title: elem,
        func: () => setType(getEstimateDropdownType(index)),
      }));
      setDropdownInfo(dropDownData);
    }
  }, [dropTitles]);

  useEffect(() => {
    if (type) {
      handleCalc();
    }
  }, [type]);

  useEffect(() => {
    const dailyText = translate('dividends.estimated.daily');
    const monthlyText = translate('dividends.estimated.monthly');
    const quarterlyText = translate('dividends.estimated.quarterly');

    setDropTitles([dailyText, monthlyText, quarterlyText]);
  }, [language]);

  useEffect(() => {
    if (tokenWithNetworksList) {
      const currenciesData = getDefaultCurrenciesTemplate(tokenWithNetworksList?.tokenWithNetworks);

      const sortedCurrencies = currenciesData.filter((el) => el.displayName !== ETokenCode.STAR);
      setTokenData(sortedCurrencies);
    }
  }, [tokenWithNetworksList]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <img src={dividendsIcon} alt="dividends" />
        <p>{translate('dividends.calculator.title.desktop')}</p>
      </div>
      <div className={styles.body}>
        <div className={styles.bodyInp}>
          <InputCustom
            type="text"
            id="percent"
            name="percent"
            inputwidth="100%"
            placeholder={translate('dividends.calculator.input.placeholder')}
            value={starAmount}
            onChange={handleChangePercent}
            maxLength={10}
            icon={getImgSrc('STAR')}
          />
        </div>
        <Button
          className={styles.button}
          variant={ButtonType.secondary}
          title={translate('dividends.calculator.button.title')}
          width="100%"
          onclick={handleCalc}
          color="#2A3E66"
        />
        <DropDown data={dropdownInfo} width="100%" />
        <CurrencyList balances={balance.length ? balance : tokenData} />
        <Button
          className={styles.button}
          variant={ButtonType.primary}
          title={translate('general.buttons.close')}
          width="100%"
          onclick={handleClose}
        />
      </div>
    </div>
  );
};

export default DividendsCalculator;
