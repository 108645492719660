import { useEffect, useMemo, useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';

import { useLocalization } from 'components/Internationalization';
import { getRankImg, getVipRankNum } from 'func/prepareDataCashback';

import RankSystemSliderStyles from './styles';
import { IRankSlider } from './types';

const RankSlider: React.FC<IRankSlider> = ({ className = '', rank, ranks, onChangeRank }) => {
  const slider = useRef<Slider>(null);
  const { translate } = useLocalization();

  const [vipRank, setVipRank] = useState(0);

  const settings: Settings = useMemo(
    () => ({
      dots: false,
      slidesToShow: 1,
      speed: 200,
      arrows: true,
      className: 'rank__slider',
      autoplay: false,
      slidesToScroll: 1,
      beforeChange: (_, next) => {
        onChangeRank(next + 1);
      },
    }),
    [ranks],
  );

  useEffect(() => {
    if (rank) {
      slider.current?.slickGoTo(rank);
    }
  }, [rank]);

  useEffect(() => {
    if (ranks) {
      const newVipRank = getVipRankNum(ranks);

      setVipRank(newVipRank);
    }
  }, [ranks, rank]);

  return (
    <RankSystemSliderStyles className={className}>
      <Slider ref={slider} {...settings}>
        {ranks.map((rank) => (
          <div key={rank.id} className="rank__slider__wrapper">
            <div className="rank__slider__image--wrapper">
              <img className="rank__slider__image" src={getRankImg(rank.id)} alt={`${rank.id} rank image`} />
            </div>
            <div className="rank__slider__info">
              <span className="rank__title">{`${translate('cashback.ranks.rank.text')} ${rank.id}`}</span>
              {rank.id >= vipRank ? <span className="rank__vip">{translate('cashback.ranks.vip.text')}</span> : null}
            </div>
          </div>
        ))}
      </Slider>
    </RankSystemSliderStyles>
  );
};

export default RankSlider;
