import { useEffect, useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { useQuery } from '@apollo/client';

import { useLocalization } from 'components/Internationalization';
import { OutLinks, UserType } from 'components/constants/constants';
import { GET_MISSION_ROUND } from 'graphQl/query/missions';
import { Links } from 'components/constants';
import { useAppSelector } from 'hooks/useAppSelector';
import { getSidebarNav, INavLink } from 'components/constants/nav';
import { userProfile } from 'store/user/user.selectors';

import NavLink from 'components/Base/Links/NavLink';
// import Rakeback from './components/Rakeback';
import SidebarGamesItem from './components/SidebarGamesItem';
import SelectLanguage from './components/Language';

import SidebarStyle from './styles';
import { ISidebar } from './types';

const Sidebar: React.FC<ISidebar> = ({ toggle }) => {
  const { language, translate } = useLocalization();

  const [nav, setNav] = useState<INavLink[]>([]);

  const user = useAppSelector(userProfile);

  const { data: missionRoundData } = useQuery(GET_MISSION_ROUND, {
    fetchPolicy: 'cache-only',
  });

  useEffect(() => {
    const newNav = getSidebarNav(translate);

    setNav(newNav);
  }, [language]);

  const checkCondition = (link: Links | string) => {
    if (link === Links.missions) {
      return Boolean(missionRoundData);
    }

    return true;
  };

  const handleMenu = () => scroll.scrollTo(0, { duration: 0, delay: 0 });

  return (
    <SidebarStyle toggle={toggle}>
      <nav className="sidebar__sticky">
        {/* <Rakeback /> */}
        <SidebarGamesItem onMenu={handleMenu} />
        {nav.map((elem) => {
          let passCondition = !elem.withCondition;

          if (!passCondition) {
            passCondition = checkCondition(elem.link);
          }

          return passCondition ? (
            <div className="sidebar__item" key={elem.title}>
              {elem.title === 'Referral' && user?.type === UserType.affiliate ? (
                <a href={OutLinks.affiliateApp} target="_blank" rel="noreferrer">
                  <div className="sidebar__item-content" onClick={handleMenu}>
                    <img src={elem.src} alt="icon" className="sidebar__icon" />
                    <h4 className="sidebar__item-title">Affiliate</h4>
                  </div>
                </a>
              ) : (
                <NavLink caseSensitive to={elem.link}>
                  <div className="sidebar__item-content" onClick={handleMenu}>
                    <img src={elem.src} alt="icon" className="sidebar__icon" />
                    <h4 className="sidebar__item-title">{elem.title}</h4>
                  </div>
                </NavLink>
              )}
            </div>
          ) : null;
        })}
        <div className="sidebar__language--wrap">
          <SelectLanguage />
        </div>
      </nav>
      {!toggle && <div className="sidebar__footer" />}
    </SidebarStyle>
  );
};

export default Sidebar;
