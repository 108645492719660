import firstPlace from 'assets/img/Table/1.webp';
import secondPlace from 'assets/img/Table/2.webp';
import thirdPlace from 'assets/img/Table/3.webp';

export const iconByPlace = (place: string | number): string | null => {
  const formattedPlace = String(place);

  switch (formattedPlace) {
    case '1': {
      return firstPlace;
    }
    case '2': {
      return secondPlace;
    }
    case '3': {
      return thirdPlace;
    }
    default: {
      return null;
    }
  }
};
