import { IVipPoint } from 'components/Pages/BoonusPage/components/VipProgress/components/VipProgressDot/types';

export const getVipPointByWager = (wager: number): string => {
  const k = wager / 1000;

  if (k < 1000) {
    return `${k}K`;
  }

  const m = wager / 1000000;

  if (m < 1000) {
    return `${m}M`;
  }

  return String(wager);
};

export const getVipProgress = (currentWager: number, points: IVipPoint[]): string => {
  if (!currentWager) return '0';

  const currentPoint = [...points].reverse().find((p) => p.wager <= currentWager);

  if (!currentPoint) return '0';

  const nextPoint = points.find((p) => p.wager > currentWager);

  if (!nextPoint) return '100';

  const passedPoints = points.filter((p) => p.wager <= currentWager).length;
  const pointsLength = points.length;
  const widthPerPoint = 100 / pointsLength + 0.2;

  const progress = currentWager - currentPoint.wager;
  const diff = nextPoint.wager - currentPoint.wager;
  const nextPointProgress = progress / diff;
  const additionalWidth = widthPerPoint * nextPointProgress;

  const formattedPassedPoints = passedPoints > 0 ? passedPoints - 1 : 0;
  const width = formattedPassedPoints * widthPerPoint + additionalWidth;

  return width.toFixed(2);
};

export const checkIsVip = (vipRank: number, userRank = 1) => {
  return userRank >= vipRank;
};
