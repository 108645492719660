export enum SoliticsEmit {
  login = 'login',
  logout = 'logout',
}

interface SoliticsPayload<E extends SoliticsEmit, D = any> {
  type: E;
  data: D;
}

export interface SoliticsLoginPayloadData {
  memberId: string;
  email?: string;
}

type SoliticsLoginPayload = SoliticsPayload<SoliticsEmit.login, SoliticsLoginPayloadData>;
type SoliticsLogoutPayload = SoliticsPayload<SoliticsEmit.logout, undefined>;

export interface ISoliticsContext {
  emit: (payload: SoliticsEmitPayload) => void;
}

export type SoliticsEmitPayload = SoliticsLoginPayload | SoliticsLogoutPayload;
