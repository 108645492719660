import axios from 'axios';
import { pathOr } from 'ramda';
import { useRef } from 'react';
import { shutdown as intercomShutdown } from '@intercom/messenger-js-sdk';

import config from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setToken } from 'store/user';
import { ITokenPayload } from 'types/requestTypes';
import { REFRESH_TOKEN } from 'graphQl/query/auth/auth';
import { getRefreshToken, setAuthToken, setRefreshToken } from 'func/auth';
import { useSolitics } from 'components/SoliticsProvider/context';
import { SoliticsEmit } from 'components/SoliticsProvider/types';

import SessionManagementContext from './context';
import type { RefreshTokensReturn } from './types';

const { graphqlUrl } = config;

const SessionManagementProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { emit } = useSolitics();

  const queryRef = useRef<RefreshTokensReturn | null>(null);

  const dispatch = useAppDispatch();

  const logout = (redirectUrl?: string): void => {
    emit({ type: SoliticsEmit.logout, data: undefined });

    localStorage.clear();
    sessionStorage.clear();
    dispatch(setToken(null));
    intercomShutdown();

    if (redirectUrl) {
      window.open(redirectUrl, '_self');
      return;
    }

    window.location.reload();
  };

  const refreshTokens = async (): Promise<ITokenPayload | null> => {
    const token = getRefreshToken();

    if (token) {
      return axios
        .post(graphqlUrl, {
          query: REFRESH_TOKEN.loc?.source?.body,
          operationName: 'refreshTokens',
          variables: { refreshToken: token },
        })
        .then((res) => {
          const tokens = pathOr<ITokenPayload | undefined>(undefined, ['data', 'data', 'refreshTokens'], res);

          if (tokens) {
            const { authToken, refreshToken: newRefreshToken } = tokens;

            if (dispatch) {
              setAuthToken(authToken);
              setRefreshToken(newRefreshToken);

              dispatch(setToken(authToken));
            }

            return tokens;
          }

          logout();
          return null;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('[ERROR_WHILE_REFRESH_TOKENS]', err);

          logout();
          return null;
        });
    }

    return null;
  };

  const handleRefreshToken = async () => {
    if (queryRef.current) {
      return queryRef.current;
    }

    const query = refreshTokens().finally(() => (queryRef.current = null));

    queryRef.current = query;

    return query;
  };

  return (
    <SessionManagementContext.Provider value={{ refreshTokens: handleRefreshToken, logout }}>
      {children}
    </SessionManagementContext.Provider>
  );
};

export default SessionManagementProvider;
