import { gql } from '@apollo/client';

export const RAKEBACK = gql`
  query rakeback {
    rakeback {
      availableBalance
      tokenCode
      withdrawalAt
      totalCollected
    }
  }
`;

export const COLLECT_RAKEBACK = gql`
  mutation collectRakeback {
    collectRakeback
  }
`;

export const CONVERT_RAKEBACK = gql`
  mutation convertRakeback($token: String!) {
    convertRakeback(token: $token)
  }
`;
