import { pathOr } from 'ramda';
import { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';

import { useAppSelector } from 'hooks/useAppSelector';
import { userProfile } from 'store/user/user.selectors';
import { CURRENT_EVENT, CURRENT_EVENT_SELF } from 'graphQl/query/events/events';
import { IEventPlayerResult, IEventSelfResult } from 'components/Base/Event/types';

import EventResults from './eventResults';
import { IEventResultsContainer } from './types';

const EventResult: React.FC<IEventResultsContainer> = ({ data }) => {
  const [eventSelf, setEventSelf] = useState<IEventSelfResult | null>(null);
  const [eventPlayers, setEventPlayers] = useState<IEventPlayerResult[]>([]);

  const profile = useAppSelector(userProfile);

  const { data: dataEvent, loading } = useQuery(CURRENT_EVENT, {
    variables: { id: data.id },
    fetchPolicy: 'no-cache',
    pollInterval: 60_000, // 1 min
  });
  const [fetchSelfResult, { data: dataSelf, loading: loadingSelf }] = useLazyQuery(CURRENT_EVENT_SELF, {
    variables: { id: data.id },
    fetchPolicy: 'no-cache',
    pollInterval: 60_000, // 1 min
  });

  useEffect(() => {
    if (profile && data.id) {
      fetchSelfResult();
    }
  }, [profile, data]);

  useEffect(() => {
    if (dataSelf) {
      const selfResult = pathOr<IEventSelfResult | null>(null, ['currentUserEventResult'], dataSelf);

      setEventSelf(selfResult);
    }
  }, [dataSelf]);

  useEffect(() => {
    if (dataEvent) {
      const eventResults = pathOr<IEventPlayerResult[]>([], ['eventCurrentResult'], dataEvent);

      setEventPlayers(eventResults);
    }
  }, [dataEvent]);

  return (
    <EventResults
      data={data}
      eventSelf={eventSelf}
      eventPlayers={eventPlayers}
      loading={loading || (profile && loadingSelf)}
      token={Boolean(profile)}
    />
  );
};

export default EventResult;
