import { useContext, useEffect, useState } from 'react';

import { ITotalCollected } from 'components/Pages/BoonusPage/components/BonusTotalCollected/types';
import { amount, getImgSrc } from 'func/common';
import { ETokenCode } from 'components/constants/constants';
import { totalCollectedUsd } from 'func/prepareDataBonus';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';
import { ContextPopUps } from 'context';

import DropDown from 'components/Base/DropDown';
import Button from 'components/Base/Button';

import styles from './styles.module.scss';
import { ETotalCollected, ITotalCollectedInfo } from './types';
import { TOTAL_COLLECTED_OPTION } from './constants';

const TotalCollectedInfo: React.FC<ITotalCollectedInfo> = ({ data }) => {
  const { setPopUpsOpen } = useContext(ContextPopUps);
  const { translate } = useLocalization();

  const [currencies, setCurrencies] = useState<ITotalCollected[]>([]);
  const [totalCurrency, setTotalCurrency] = useState('0');
  const [selector, setSelector] = useState(ETotalCollected.dividends);
  const [dropSelectors, setDropSelectors] = useState([]);

  useEffect(() => {
    const newSelectors = TOTAL_COLLECTED_OPTION.map((s) => ({
      ...s,
      title: translate(s.title),
      func: () => setSelector(s.value),
    }));

    setDropSelectors(newSelectors);
  }, []);

  useEffect(() => {
    if (selector && data) {
      const newCurrencies = data[selector];

      const totalCurrency = totalCollectedUsd(newCurrencies);

      setCurrencies(newCurrencies);
      setTotalCurrency(totalCurrency);
    }
  }, [selector, data]);

  const handleClose = () => {
    setPopUpsOpen(null);
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{translate('bonus.total.colleted.title')} (USD)</p>
      <div className={styles.total}>
        <img src={getImgSrc(ETokenCode.USD)} alt="usd" />
        <p>
          {amount(data.total)}
          {ETokenCode.USD}
        </p>
      </div>
      <DropDown data={dropSelectors} width="100%" />
      <div className={styles.totalCurrency}>
        <p className={styles.totalCurrencyTitle}>{translate('dividends.balance.collected.title')}</p>
        <div className={styles.currency}>
          <img src={getImgSrc(ETokenCode.USD)} alt="usd" />
          <p>
            {amount(totalCurrency)} {ETokenCode.USD}
          </p>
        </div>
      </div>
      <div className={styles.currencyList}>
        {currencies.map((c) => (
          <div key={c.tokenCode} className={styles.currencyListItem}>
            <p className={styles.currencyListItemTitle}>{c.displayName}</p>
            <div className={styles.currency}>
              <img src={getImgSrc(c.tokenCode)} alt={c.displayName} />
              <p>{amount(c.totalCollected)}</p>
            </div>
          </div>
        ))}
      </div>
      <Button variant={ButtonType.primary} title={translate('general.buttons.close')} onClick={handleClose} />
    </div>
  );
};

export default TotalCollectedInfo;
