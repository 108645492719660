import React from 'react';

import FAQContentItem from './FAQContentItem';
import { IFAQContent } from './types';

const FAQContent: React.FC<IFAQContent> = ({ sectionArticles }) => (
  <div className="faq__content--wrap">
    <div className="faq__content--item--wrap">
      {sectionArticles.map((a, i) =>
        i % 2 === 0 ? <FAQContentItem key={a.id} title={a.title} description={a.body} /> : null,
      )}
    </div>
    <div className="faq__content--item--wrap">
      {sectionArticles.map((a, i) =>
        i % 2 === 1 ? <FAQContentItem key={a.id} title={a.title} description={a.body} /> : null,
      )}
    </div>
  </div>
);

export default FAQContent;
