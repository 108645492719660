import { TableElem, Token } from 'types/requestTypes';
import { ETokenCode, TABLE_CLASSNAMES } from 'components/constants/constants';

import UserName from 'components/Base/Table/components/UserName';

import { amount, getDate, getImgSrc } from './common';
import Big from 'big.js';

interface DataElem {
  title: string | number;
  src: string;
  displayName?: string;
}

interface ReferralActivityType {
  __typename?: string;
  alias?: string;
  createdAt?: string;
  totalMined?: string;
  totalWager?: string;
  avatar?: {
    avatar?: string;
  };
  rank: {
    id?: number;
  };
  wallets: Array<{ tokenCode?: string }>;
}

interface ReferralBalance {
  availableBalance?: number;
  __typename?: string;
  token?: Token;
  totalCollected?: string;
}

interface ReferralCollections {
  amount?: number;
  createdAt?: string;
  __typename?: string;
  token?: Token;
}

export const prepareReferralActivity = (arrayData: ReferralActivityType[]): TableElem[] => {
  const tableData = [];
  arrayData.forEach((item) => {
    item.wallets.forEach((elem) => {
      const elemArray = [];
      if (elem.tokenCode === 'BTC') {
        const jsxElem = <UserName src={item.avatar?.avatar} userName={item.alias} rank={item.rank.id} />;
        elemArray.push(jsxElem);

        const date = {
          title: getDate(item.createdAt),
          columnClass: TABLE_CLASSNAMES.column.default,
          textClass: TABLE_CLASSNAMES.text.dateFormat,
        };
        elemArray.push(date);

        const titleElem = elem.tokenCode === 'BTC' && {
          title: String(amount(item.totalWager)) + ' ' + elem.tokenCode,
          src: getImgSrc(elem.tokenCode),
          columnClass: TABLE_CLASSNAMES.column.default,
          imageClass: TABLE_CLASSNAMES.images.currency,
          textClass: TABLE_CLASSNAMES.text.default,
        };
        elemArray.push(titleElem);
        tableData.push(elemArray);
      }
    });
  });
  return tableData;
};

export const prepareReferralBalance = (arrayData: ReferralBalance[]): Array<string & DataElem> | DataElem[] => {
  const balanceData = [];

  arrayData.forEach((elem) => {
    const elemObj = {
      title: amount(elem.availableBalance),
      src: elem.token.tokenCode,
      displayName: elem.token.displayName,
    };
    balanceData.push(elemObj);
  });
  return balanceData;
};

export const prepareReferralCollections = (arrayData: ReferralCollections[]): TableElem[][] => {
  const tableData = arrayData.map((elem) => {
    const date = {
      title: getDate(elem.createdAt),
      columnClass: TABLE_CLASSNAMES.column.default,
      textClass: TABLE_CLASSNAMES.text.dateFormat,
    };
    const elemObj = {
      title: `${amount(elem.amount)} ${elem.token.displayName}`,
      src: getImgSrc(elem.token.displayName),
      columnClass: TABLE_CLASSNAMES.column.default,
      textClass: TABLE_CLASSNAMES.text.dateFormat,
      imageClass: TABLE_CLASSNAMES.images.currency,
    };
    return [date, elemObj];
  });

  return tableData;
};

export const prepareReferralBalanceDashboard = (
  arrayData: ReferralBalance[],
): Array<string & DataElem> | DataElem[] => {
  const balanceData = [];
  const emptyData = [
    { title: 0, src: 'BTC' },
    { title: 0, src: 'STAR' },
  ];
  if (arrayData.length < 1) {
    return emptyData;
  }

  arrayData.forEach((elem) => {
    const elemObj = {
      title: elem.availableBalance,
      src: elem.token.tokenCode,
      displayName: elem.token.displayName,
    };
    balanceData.push(elemObj);
  });
  return balanceData;
};

export const prepareReferralTotalCollected = (arrayData: ReferralBalance[]): DataElem[] => {
  const properData = arrayData.map((elem) => ({
    title: amount(elem.totalCollected),
    src: elem.token.tokenCode,
    displayName: elem.token.displayName,
  }));
  return properData;
};

export const getTotalEarnedStars = (referrals: ReferralBalance[]): string => {
  const stars = referrals.find((e) => e.token.tokenCode === ETokenCode.STAR);

  if (!stars) return '0';

  const amount = Big(stars.availableBalance).plus(stars.totalCollected).valueOf();

  return amount;
};
