import TableCell from 'components/Base/NewTable/components/TableCell';

import styles from './styles.module.scss';
import { ITableRow } from './types';

const TableRow: React.FC<ITableRow> = ({ id, className = '', cells, index = 0, ...props }) => {
  return (
    <tr id={id} className={styles.row} {...props}>
      {cells.map((c) => (
        <TableCell key={c.id} even={(index + 1) % 2 === 0} {...c} />
      ))}
    </tr>
  );
};

export default TableRow;
