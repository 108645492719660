type Env = 'develop' | 'stage' | 'prod';

interface IConfig {
  env: Env;
  devPassword: string;
  i18nDebug: boolean;
  baseUrl: string;
  graphqlUrl: string;
  socketUrl: string;
  ghostUrl: string;
  ghostKey: string;
  mediaUrl: string;
  recaptchaKey: string;
  codesandboxUrl: string;
  sha256ValidatorUrl: string;
  newRelicApplicationId: string;
  pragmaticUrl: string;
  pragmaticCasinoId: string;
  vipTelegramGroup: string;
  vipTelegramManager: string;
  intercomAppId: string;
  soliticsBranch: string;
  soliticsBrand: string;
  soliticsToken: string;
}

const config: IConfig = {
  env: (process.env.REACT_APP_ENV as Env) || 'develop',
  i18nDebug: process.env.REACT_APP_INTERNALIZATION_DEBUG === 'true',
  devPassword: process.env.REACT_APP_DEV_PASSWORD || '',
  baseUrl: process.env.REACT_APP_BASE_URL || '',
  graphqlUrl: process.env.REACT_APP_GRAPHQL_URL || '',
  socketUrl: process.env.REACT_APP_SOCKET_URL || '',
  ghostUrl: process.env.REACT_APP_GHOST_URL || '',
  ghostKey: process.env.REACT_APP_GHOST_KEY || '',
  mediaUrl: process.env.REACT_APP_MEDIA_URL || '',
  recaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY || '',
  codesandboxUrl: process.env.REACT_APP_CODESANDBOX_URL || '',
  sha256ValidatorUrl: process.env.REACT_APP_SHA256_VALIDATOR_URL || '',
  newRelicApplicationId: process.env.REACT_APP_NEW_RELIC_APPLICATION_ID || '',
  pragmaticUrl: process.env.REACT_APP_PRAGMATIC_SOCKET_URL || '',
  pragmaticCasinoId: process.env.REACT_APP_PRAGMATIC_CASINO_ID || '',
  vipTelegramGroup: process.env.REACT_APP_VIP_TELEGRAM_GROUP || '',
  vipTelegramManager: process.env.REACT_APP_VIP_TELEGRAM_MANAGER || '',
  intercomAppId: process.env.REACT_APP_INTERCOM_APP_ID || '',
  soliticsBranch: process.env.REACT_APP_SOLITICS_BRANCH || '',
  soliticsBrand: process.env.REACT_APP_SOLITICS_BRAND || '',
  soliticsToken: process.env.REACT_APP_SOLITICS_TOKEN || '',
};

export const media = {
  avatars: `${config.mediaUrl}/avatars/`,
  games: `${config.mediaUrl}/games_thumbnail/`,
  providers: `${config.mediaUrl}/providers_thumbnail/`,
  events: `${config.mediaUrl}/events_thumbnail/`,
  slider: `${config.mediaUrl}/sliders_thumbnail/`,
  background: `${config.mediaUrl}/backgrounds_thumbnail/`,
  logo: `${config.mediaUrl}/logo_thumbnail/`,
  footerLogos: `${config.mediaUrl}/footer_logos_thumbnail/`,
  wheel: `${config.mediaUrl}/wheel/`,
  promoMaterials: `${config.mediaUrl}/PromoMaterials/`,
};

export default config;

declare global {
  interface Window {
    newRelicApplicationId: string;
  }
}
