import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'animate.css';

import createStore from 'store';

import ErrorBoundary from 'components/ErrorBoundary';
import CatchErrorPage from 'components/Pages/CatchErrorPage';
import ApolloClientProvider from 'components/ApolloClient';
import InternationalizationProvider from 'components/Internationalization';
import SessionManagementProvider from 'context/contextSessionManagement';

import App from './App';

import './styles/app.css';
import './styles/normalize.css';
import './assets/fonts/fonts.css';
import SoliticsProvider from 'components/SoliticsProvider';

const { store, persistor } = createStore();

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <ErrorBoundary fallback={<CatchErrorPage />}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <SoliticsProvider>
          <SessionManagementProvider>
            <ApolloClientProvider>
              <InternationalizationProvider>
                <App />
              </InternationalizationProvider>
            </ApolloClientProvider>
          </SessionManagementProvider>
        </SoliticsProvider>
      </PersistGate>
    </Provider>
  </ErrorBoundary>,
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
