import config from 'config';

import SoliticsContext from './context';
import { SoliticsEmit, SoliticsEmitPayload, SoliticsLoginPayloadData } from './types';

const { soliticsToken, soliticsBranch, soliticsBrand } = config;

const SoliticsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const handleLogin = (data: SoliticsLoginPayloadData) => {
    window.$solitics.loginSuccess({ ...data, popupToken: soliticsToken, branch: soliticsBranch, brand: soliticsBrand });
  };

  const handleLogout = () => {
    window.$solitics.onLogout();
  };

  const handleEmit = ({ type, data }: SoliticsEmitPayload) => {
    if (!window.$solitics) return;

    switch (type) {
      case SoliticsEmit.login: {
        return handleLogin(data);
      }
      case SoliticsEmit.logout: {
        return handleLogout();
      }
    }
  };

  return <SoliticsContext.Provider value={{ emit: handleEmit }}>{children}</SoliticsContext.Provider>;
};

export default SoliticsProvider;
