import React, { useContext } from 'react';

import { ISoliticsContext } from './types';

const SoliticsContext = React.createContext<ISoliticsContext>({
  emit: () => null,
});

export default SoliticsContext;

export const useSolitics = () => useContext(SoliticsContext);
