import { useLocalization } from 'components/Internationalization';
import { amount, getAmountWithoutZeroEnd, getImgSrc } from 'func/common';
import { ETokenCode } from 'components/constants/constants';

import Avatar from 'components/Base/Avatar';

import styles from './styles.module.scss';
import { IEventResultSelf } from './types';

const EventResultSelf: React.FC<IEventResultSelf> = ({
  result,
  profile,
  vip,
  prizeCode,
  wagerTokenCode = ETokenCode.BTC,
}) => {
  const { translate } = useLocalization();

  return (
    <div className={styles.wrapper}>
      <div className={styles.user}>
        <Avatar className={styles.avatar} userAvatar={profile.avatar?.avatar} vip={vip} />
        <div className={styles.userInfo}>
          <p className={styles.alias}>{profile.alias}</p>
          <div className={styles.rank}>
            <span className={styles.rankTitle}>
              {translate('popups.rank.tooltip')} {profile.rank.id}
            </span>
            {vip ? <span className={styles.rankVip}>{translate('games.tabs.vip')}</span> : null}
          </div>
        </div>
      </div>
      <ul className={styles.result}>
        <li className={styles.resultItem}>
          <p className={styles.subtitle}>Place:</p>
          <p className={styles.text}>{result?.place || '-'}</p>
        </li>
        <li className={styles.resultItem}>
          <p className={styles.subtitle}>Wager:</p>
          <div className={styles.subcontent}>
            <img src={getImgSrc(wagerTokenCode)} alt="wager icon" />
            <p className={styles.text}>{amount(result?.totalWager || 0, true)}</p>
          </div>
        </li>
        <li className={styles.resultItem}>
          <p className={styles.subtitle}>Prize:</p>
          <div className={styles.subcontent}>
            <img src={getImgSrc(prizeCode)} alt="prize icon" />
            <p className={styles.text}>
              {getAmountWithoutZeroEnd(result?.prize || '0')} {prizeCode}
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default EventResultSelf;
