import React from 'react';

import loader1 from 'assets/img/loader/green-alien-wear-sunglasses-loader.webp';
import { getLoaderPages } from 'func/prepareDataHomePage';

import LoaderStyle from './styles';
import { ILoader } from './types';

const Loader: React.FC<ILoader> = ({ className = '', pages }) => (
  <LoaderStyle className={className} pages={pages ? 'true' : ''}>
    <img src={pages ? getLoaderPages() : loader1} className="loader_img" alt="loader" />
    <div className="blur" />
  </LoaderStyle>
);

export default React.memo(Loader);
