import { pathOr } from 'ramda';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import warningIcon from 'assets/img/common/warning-icon.webp';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { setUserBonuses } from 'store/data';
import { IUserBonus, UserBonusStatus } from 'components/Pages/BoonusPage/components/UserBonus/types';
import { GET_MY_BONUSES } from 'graphQl/query/bonus/indes';
import { SELECT_BONUS } from 'graphQl/mutations/bonus/bonus';
import { getBonusesDeposit } from 'func/prepareDataBonus';
import { dataIsActiveUserBonus } from 'store/data/data.selectors';
import { useLocalization } from 'components/Internationalization';

import DropDown from 'components/Base/DropDown';

import styles from './styles.module.scss';

const DepositBonus: React.FC = () => {
  const dispatch = useAppDispatch();
  const isActiveUserBonus = useAppSelector(dataIsActiveUserBonus);
  const { language, translate } = useLocalization();

  const [bonusList, setBonusList] = useState([]);
  const [infoOpen, setInfoOpen] = useState(false);

  const { data } = useQuery(GET_MY_BONUSES, { fetchPolicy: 'cache-and-network' });
  const [selectUserBonus] = useMutation(SELECT_BONUS);

  const handleRequest = async (id: string) => {
    await selectUserBonus({ variables: { input: id || '' } });
  };

  useEffect(() => {
    if (data) {
      const bonuses = pathOr<IUserBonus[]>([], ['myBonuses'], data);
      const filtered = getBonusesDeposit(bonuses);
      const selected = bonuses.find(
        (el) =>
          el.selected && [UserBonusStatus.available, UserBonusStatus.spins].includes(el.status as UserBonusStatus),
      );

      const listAvailable = filtered.map((el) => ({
        id: el.id,
        title: el.bonus.name,
        func: async () => handleRequest(el.id),
      }));

      const noBonusEl = {
        title: translate('popups.wallet.deposit.bonus.no-selected'),
        func: async () => handleRequest(''),
      };
      const selectBonusEl = {
        title: translate('popups.wallet.deposit.bonus.title'),
        func: () => {},
        hideFirstElem: '1111',
      };

      if (selected) {
        const availableWithSelected = listAvailable.filter((el) => el.id !== selected.id);
        const first = { title: selected.bonus.name, func: async () => handleRequest(selected.id) };
        setBonusList([first, ...availableWithSelected, noBonusEl]);
      } else if (filtered.length) {
        setBonusList([selectBonusEl, noBonusEl, ...listAvailable]);
      }
      dispatch(setUserBonuses(data.myBonuses));
    }
  }, [data, language]);

  const handleBlur = () => {
    setInfoOpen(false);
  };

  const handleInfo = () => {
    setInfoOpen(true);
  };

  if (!bonusList.length || isActiveUserBonus) return null;

  return (
    <div className={styles.depositBonusWrap}>
      <div className={styles.depositBonusSelection}>
        <p>{translate('popups.wallet.deposit.bonus.title')}:</p>
        <button
          className={styles.depositBonusBtn}
          type="submit"
          onClick={handleInfo}
          onBlur={handleBlur}
          onMouseEnter={() => handleInfo()}
          onMouseLeave={() => handleBlur()}
        >
          i
        </button>
      </div>
      {infoOpen && (
        <div className={styles.depositBonusInfo}>
          <h2>
            <img src={warningIcon} alt="icon" />
            {translate('popups.wallet.deposit.bonus.warning.title')}
          </h2>
          <h3>{translate('popups.wallet.deposit.bonus.warning.desc')}</h3>
        </div>
      )}
      <DropDown data={bonusList} width="100%" textPadding="20px" />
    </div>
  );
};

export default DepositBonus;
