import React, { useRef, useState } from 'react';
import ReactPaginate from 'react-paginate';

import arrowIcon from 'assets/img/common/arrowIcon.svg';

import PaginationStyle from './styles';
import { IPagination } from './types';

const Pagination: React.FC<IPagination> = ({
  className = '',
  itemsLength,
  perPage,
  onPageChanged,
  setCurrentPage,
  pageDisplayed,
  pageMargin = 0,
  forcePage,
  popUpTable,
}) => {
  const pagination = useRef();

  const [arrowLeft, setArrowLeft] = useState(0);

  const setPage = ({ selected }) => {
    const pageSlice = selected === 0 ? 1 : selected * perPage;
    if (setCurrentPage) setCurrentPage(pageSlice);
    if (onPageChanged) onPageChanged(pageSlice);
    setArrowLeft(selected);
  };

  return (
    <PaginationStyle className={className} arrowleft={arrowLeft} popuptable={popUpTable}>
      <ReactPaginate
        ref={pagination}
        forcePage={forcePage}
        pageCount={Math.ceil(itemsLength / perPage)}
        pageRangeDisplayed={pageDisplayed}
        marginPagesDisplayed={pageMargin}
        onPageChange={setPage}
        containerClassName="pagination"
        activeClassName="pagination__link-active"
        pageLinkClassName="pagination__link"
        breakLinkClassName="pagination__doth"
        nextLinkClassName="pagination__item"
        previousLinkClassName="pagination__item-left"
        pageClassName="pagination__link"
        breakClassName="pagination__doth"
        nextClassName="pagination__item"
        previousClassName="pagination__item-left"
        previousLabel={
          <>
            <img src={arrowIcon} alt="icon" className="pagination__arrow-left" />
          </>
        }
        nextLabel={
          <>
            <img src={arrowIcon} alt="icon" className="pagination__arrow-right" />
          </>
        }
      />
    </PaginationStyle>
  );
};

export default Pagination;
